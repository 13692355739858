define("ember-svg-jar/inlined/pin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.344 6.222a6.375 6.375 0 00-9.87-4.702 6.42 6.42 0 00-2.82 4.702A6.345 6.345 0 002.5 11.345l3.975 3.982a.75.75 0 001.065 0l3.96-3.982a6.345 6.345 0 001.845-5.123zm-2.895 4.065L7 13.738l-3.45-3.45a4.868 4.868 0 01-1.402-3.915A4.927 4.927 0 014.314 2.75a4.852 4.852 0 015.37 0 4.928 4.928 0 012.168 3.607 4.867 4.867 0 01-1.403 3.93zM7 3.5a3.375 3.375 0 103.375 3.375A3.383 3.383 0 007 3.5zm0 5.25A1.875 1.875 0 117 5a1.875 1.875 0 010 3.75z\" fill=\"#6D6E71\"/>",
    "attrs": {
      "width": "14",
      "height": "16",
      "viewBox": "0 0 14 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});