define("ember-svg-jar/inlined/filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1.608 1.594l5.238 6.49c.101.125.183.276.246.45.063.176.095.357.095.545v5.327l1.626-1.2V9.078c0-.188.032-.37.095-.544.063-.175.145-.332.246-.47v.02l5.238-6.49H1.608zm0-1.594h12.784c.442 0 .82.156 1.135.469.315.313.473.688.473 1.125 0 .188-.031.37-.095.544a1.333 1.333 0 01-.264.45h.019l-5.24 6.49v4.127c0 .263-.056.504-.17.722a1.601 1.601 0 01-.472.554l-1.608 1.2c-.138.1-.29.178-.454.235a1.62 1.62 0 01-.53.084c-.44 0-.819-.156-1.134-.469a1.529 1.529 0 01-.473-1.125V9.079L.34 2.589c-.1-.138-.182-.291-.245-.46A1.516 1.516 0 010 1.594C0 1.157.158.782.473.47A1.554 1.554 0 011.608 0z\" fill=\"#fff\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});