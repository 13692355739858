define("ember-svg-jar/inlined/globe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M4.456 9.455a11.362 11.362 0 010-2.91h-2.84a6.567 6.567 0 000 2.91h2.84zm1.885 4.878a11.35 11.35 0 01-1.596-3.424h-2.61a6.56 6.56 0 004.206 3.424zM14.545 8c0-.5-.056-.987-.162-1.455h-2.839a11.362 11.362 0 010 2.91h2.84A6.57 6.57 0 0014.545 8zm-3.29 2.91a11.349 11.349 0 01-1.596 3.423 6.56 6.56 0 004.206-3.424h-2.61zm-1.18-1.455c.071-.476.107-.96.107-1.455a9.85 9.85 0 00-.107-1.455h-4.15c-.071.476-.107.96-.107 1.455 0 .494.036.979.106 1.455h4.151zM8 14.14c.8-1.024 1.38-2.1 1.739-3.23H6.26c.36 1.13.939 2.206 1.739 3.23zM4.745 5.09a11.35 11.35 0 011.596-3.423A6.56 6.56 0 002.135 5.09h2.61zM9.66 1.668a11.35 11.35 0 011.596 3.424h2.61A6.56 6.56 0 009.66 1.667zm.08 3.424C9.379 3.96 8.8 2.884 8 1.86c-.8 1.024-1.38 2.1-1.739 3.23H9.74zM8 16A8 8 0 118 0a8 8 0 010 16z\" fill=\"#D9D9D9\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});