define("ember-svg-jar/inlined/external-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M12.8 7.056a.8.8 0 00-.8.8V13.6a.8.8 0 01-.8.8H2.4a.8.8 0 01-.8-.8V4.8a.8.8 0 01.8-.8h5.744a.8.8 0 000-1.6H2.4A2.4 2.4 0 000 4.8v8.8A2.4 2.4 0 002.4 16h8.8a2.4 2.4 0 002.4-2.4V7.856a.8.8 0 00-.8-.8zm3.136-6.56a.8.8 0 00-.432-.432A.8.8 0 0015.2 0h-4.8a.8.8 0 100 1.6h2.872l-8.24 8.232a.8.8 0 00.26 1.311.8.8 0 00.876-.175l8.232-8.24V5.6a.8.8 0 001.6 0V.8a.8.8 0 00-.064-.304z\" fill=\"#333\"/>",
    "attrs": {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 16 16",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});