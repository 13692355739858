define("ember-svg-jar/inlined/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Shape</title><path d=\"M22 3v13a3 3 0 01-3 3H3a3 3 0 01-3-3V3a3 3 0 013-3h16a3 3 0 013 3zm-2 5H2v8a1 1 0 001 1h16a1 1 0 001-1V8zm0-2V3a1 1 0 00-1-1H3a1 1 0 00-1 1v3h18zM4 5a1 1 0 110-2 1 1 0 010 2zm3 0a1 1 0 110-2 1 1 0 010 2zm3 0a1 1 0 110-2 1 1 0 010 2zm-5 6.99a1 1 0 010-2h3a1 1 0 110 2H5zm0 3a1 1 0 010-2h7a1 1 0 110 2H5z\" fill=\"#333\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "22",
      "height": "19",
      "viewBox": "0 0 22 19",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});