define("ember-svg-jar/inlined/door", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 17.5v.547a1.667 1.667 0 01-2.146 1.596l-6.666-2A1.667 1.667 0 010 16.047V1.667C0 .747.746 0 1.667 0H12.5c.92 0 1.667.746 1.667 1.667v14.166c0 .92-.746 1.667-1.667 1.667H10zM6.63 1.667l2.201.688A1.667 1.667 0 0110 3.945v11.888h2.5V1.667H6.63zm-4.963.193v14.187l6.666 2V3.946L1.667 1.86zm4.583 9.807a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5z\" fill=\"#D9D9D9\"/>",
    "attrs": {
      "width": "15",
      "height": "20",
      "viewBox": "0 0 15 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});