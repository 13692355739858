define("ember-svg-jar/inlined/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>icon/error</title><g fill=\"none\" fill-rule=\"evenodd\"><circle fill=\"#E2001A\" cx=\"9\" cy=\"9\" r=\"9\"/><g stroke=\"#FFF\" stroke-linecap=\"square\" stroke-width=\"2\"><path d=\"M11.828 6.172l-5.656 5.656M6.172 6.172l5.656 5.656\"/></g></g>",
    "attrs": {
      "width": "18",
      "height": "18",
      "viewBox": "0 0 18 18",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});