define("@ember-data/model/util-3DHZJC9h", ["exports", "@ember/debug", "@ember/string", "@embroider/macros/runtime"], function (_exports, _debug, _string, _runtime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.c = computedMacroWithOptionalParams;
  _exports.n = normalizeModelName;
  function isElementDescriptor(args) {
    const [maybeTarget, maybeKey, maybeDesc] = args;
    return (
      // Ensure we have the right number of args
      args.length === 3 && (
      // Make sure the target is a class or object (prototype)
      typeof maybeTarget === 'function' || typeof maybeTarget === 'object' && maybeTarget !== null) &&
      // Make sure the key is a string
      typeof maybeKey === 'string' && (
      // Make sure the descriptor is the right shape
      typeof maybeDesc === 'object' && maybeDesc !== null && 'enumerable' in maybeDesc && 'configurable' in maybeDesc ||
      // TS compatibility
      maybeDesc === undefined)
    );
  }
  function computedMacroWithOptionalParams(fn) {
    return (...maybeDesc) => isElementDescriptor(maybeDesc) ? fn()(...maybeDesc) : fn(...maybeDesc);
  }
  function normalizeModelName(type) {
    if ((0, _runtime.macroCondition)((0, _runtime.config)("/home/pv-your-source/azagent/azagent/_work/5/s/node_modules/@ember-data/model").deprecations.DEPRECATE_NON_STRICT_TYPES)) {
      const result = (0, _string.dasherize)(type);
      (true && !(result === type) && (0, _debug.deprecate)(`The resource type '${type}' is not normalized. Update your application code to use '${result}' instead of '${type}'.`, result === type, {
        id: 'ember-data:deprecate-non-strict-types',
        until: '6.0',
        for: 'ember-data',
        since: {
          available: '5.3',
          enabled: '5.3'
        }
      }));
      return result;
    }
    return type;
  }
});