define("ember-svg-jar/inlined/envelope", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M79.2 67.68h-6M6 67.68h1.6M73.2 67.68h-1.6M6 67.68H.8M72.72 67.2c-.32.24-.64.48-1.12.48M6.48 67.2c.32.24.64.48 1.12.48M6 22.08h9.2M64.8 22.08h8.4M72.72 67.2c.32-.32.48-.72.48-1.12v-44M6 22.08v44c0 .48.16.88.48 1.12M7.6 67.68h64M15.2 17.28L6 22.08M64.8 17.68l8.4 4.4M64.8 22.08v-4.4M64.8 17.68V13.6M64.8 22.08v7.44M52 .8l12.8 12.8M52 .8H18.4A3.21 3.21 0 0015.2 4v13.28M15.2 17.28v4.8M15.2 30.24v-8.16M57.04 13.6h7.76M52.08 10.96c.24 1.52 1.6 2.64 3.12 2.64h1.84M52 .8v9.6c0 .24 0 .4.08.56M31.6 44.88l-9.68 8.64M6 22.08l9.2 8.16M31.6 44.88L15.2 30.24M6.48 67.2l-.48.48M6.48 67.2l15.44-13.68M47.6 44.88l9.68 8.64M64.8 29.52l8.4-7.44M64.8 29.52L47.6 44.88M72.72 67.2l.48.48M72.72 67.2L57.28 53.52M31.6 44.88h16M28.16 10.48h8.64M28.16 10.48H22.4M40.8 18.48H22.4M56.8 18.48H48M22.4 25.68h16M22.4 33.68h31.2M43.2 25.68h13.6\" stroke=\"red\" stroke-width=\"2\" stroke-miterlimit=\"10\" stroke-linecap=\"round\" stroke-linejoin=\"round\"/>",
    "attrs": {
      "width": "80",
      "height": "69",
      "viewBox": "0 0 80 69",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});