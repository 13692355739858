define("ember-svg-jar/inlined/chevron", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M1 5l4-4 4 4\" stroke=\"#666\" stroke-width=\"2\" stroke-miterlimit=\"10\"/>",
    "attrs": {
      "width": "12",
      "height": "6",
      "viewBox": "0 0 10 6",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});