define("ember-svg-jar/inlined/BE", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<mask id=\"a\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"20\" height=\"15\"><path fill=\"#fff\" d=\"M0 0h20v15H0z\"/></mask><g mask=\"url(#a)\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M6 0h8v15H6V0z\" fill=\"#FECA00\"/><path d=\"M14 0h6v15h-6V0z\" fill=\"#E31D1C\"/><path d=\"M0 0h6v15H0V0z\" fill=\"#1D1D1D\"/></g>",
    "attrs": {
      "width": "20",
      "height": "15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});