define("@sandydoo/tracked-maps-and-sets/index", ["exports", "@sandydoo/tracked-maps-and-sets/-private/map", "@sandydoo/tracked-maps-and-sets/-private/set"], function (_exports, _map, _set) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "TrackedMap", {
    enumerable: true,
    get: function () {
      return _map.TrackedMap;
    }
  });
  Object.defineProperty(_exports, "TrackedSet", {
    enumerable: true,
    get: function () {
      return _set.TrackedSet;
    }
  });
  Object.defineProperty(_exports, "TrackedWeakMap", {
    enumerable: true,
    get: function () {
      return _map.TrackedWeakMap;
    }
  });
  Object.defineProperty(_exports, "TrackedWeakSet", {
    enumerable: true,
    get: function () {
      return _set.TrackedWeakSet;
    }
  });
});