define("ember-svg-jar/inlined/pencil", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M.875 14h3.708a.874.874 0 00.621-.254l6.053-6.061 2.484-2.432a.874.874 0 000-1.242L10.033.26a.874.874 0 00-1.242 0L6.324 2.734.254 8.796a.875.875 0 00-.254.62v3.71A.875.875 0 00.875 14zM9.412 2.113l2.475 2.475-1.242 1.242L8.17 3.355l1.242-1.242zM1.749 9.775l5.187-5.187 2.476 2.476-5.187 5.187H1.749V9.775z\" fill=\"#333\"/>",
    "attrs": {
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});