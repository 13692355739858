define("ember-svg-jar/inlined/calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M13.602 1.552H11.99V.785c0-.243-.072-.435-.216-.575-.143-.14-.334-.21-.571-.21-.25 0-.447.07-.59.21-.144.14-.216.332-.216.575v.767H5.602V.785c0-.243-.072-.435-.216-.575-.143-.14-.34-.21-.59-.21-.237 0-.428.07-.571.21-.144.14-.216.332-.216.575v.767H2.4c-.675 0-1.244.225-1.706.675A2.229 2.229 0 000 3.89v10.917c0 .657.231 1.211.693 1.661.462.45 1.03.676 1.705.676h11.204c.674 0 1.243-.225 1.705-.676A2.23 2.23 0 0016 14.806V3.889c0-.658-.231-1.211-.693-1.662-.462-.45-1.03-.675-1.705-.675zM2.398 3.122H4.01v.767c0 .243.072.435.216.575.143.14.334.21.571.21.25 0 .447-.07.59-.21.144-.14.216-.332.216-.575v-.767h4.796v.767c0 .243.072.435.216.575.143.14.34.21.59.21.237 0 .428-.07.571-.21.144-.14.216-.332.216-.575v-.767h1.61c.238 0 .429.07.572.21.144.14.216.325.216.557v2.336H1.61V3.89c0-.232.072-.417.216-.557.143-.14.334-.21.571-.21zm11.204 12.45H2.398c-.237 0-.428-.07-.571-.21a.742.742 0 01-.216-.556v-7.01H14.39v7.01a.742.742 0 01-.216.557c-.143.14-.334.21-.571.21z\" fill=\"red\"/>",
    "attrs": {
      "width": "16",
      "height": "18",
      "viewBox": "0 0 16 18",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});