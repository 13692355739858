define("ember-svg-jar/inlined/info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M10 9a1 1 0 00-1 1v4a1 1 0 102 0v-4a1 1 0 00-1-1zm.38-3.92a1 1 0 00-.76 0 1 1 0 00-.33.21 1.15 1.15 0 00-.21.33 1 1 0 00.21 1.09c.097.088.209.16.33.21A1 1 0 0011 6a1.05 1.05 0 00-.29-.71 1 1 0 00-.33-.21zM10 0a10 10 0 100 20 10 10 0 000-20zm0 18a8 8 0 110-16 8 8 0 010 16z\" fill=\"red\"/>",
    "attrs": {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});