define("ember-svg-jar/inlined/bin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<title>Shape</title><path d=\"M5.444 12.8a.79.79 0 00.778-.8V7.2a.79.79 0 00-.778-.8.79.79 0 00-.777.8V12c0 .442.348.8.777.8zm7.778-9.6h-3.11v-.8c0-1.325-1.046-2.4-2.334-2.4H6.222C4.934 0 3.89 1.075 3.89 2.4v.8H.778A.79.79 0 000 4c0 .442.348.8.778.8h.778v8.8c0 1.325 1.044 2.4 2.333 2.4h6.222c1.289 0 2.333-1.075 2.333-2.4V4.8h.778A.79.79 0 0014 4a.79.79 0 00-.778-.8zm-7.778-.8c0-.442.349-.8.778-.8h1.556a.79.79 0 01.778.8v.8H5.444v-.8zm5.445 11.2a.79.79 0 01-.778.8H3.89a.79.79 0 01-.778-.8V4.8h7.778v8.8zm-2.333-.8a.79.79 0 00.777-.8V7.2a.79.79 0 00-.777-.8.79.79 0 00-.778.8V12c0 .442.348.8.778.8z\" fill=\"#6D6E71\" fill-rule=\"nonzero\"/>",
    "attrs": {
      "width": "14",
      "height": "16",
      "viewBox": "0 0 14 16",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});