define("ember-svg-jar/inlined/NL", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<mask id=\"a\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"20\" height=\"15\"><path fill=\"#fff\" d=\"M0 0h20v15H0z\"/></mask><g mask=\"url(#a)\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 0v15h20V0H0z\" fill=\"#F7FCFF\"/><mask id=\"b\" maskUnits=\"userSpaceOnUse\" x=\"0\" y=\"0\" width=\"20\" height=\"15\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M0 0v15h20V0H0z\" fill=\"#fff\"/></mask><g mask=\"url(#b)\" fill-rule=\"evenodd\" clip-rule=\"evenodd\"><path d=\"M0 0v5h20V0H0z\" fill=\"#E31D1C\"/><path d=\"M0 10v5h20v-5H0z\" fill=\"#3D58DB\"/></g></g>",
    "attrs": {
      "width": "20",
      "height": "15",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});