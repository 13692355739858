define("ember-intl/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = [["en-us", {
    "app_title": "Zehnder - Product Registration",
    "bulk": {
      "combi_flow": "CombiFlow",
      "comfo_connect": "ComfoConnect (Gateway)",
      "comfo_cool": "ComfoCool/ComfoClime (Comfort unit)",
      "comfo_fond_l": "ComfoFond-L Q (Sub-soil heat exchanger)",
      "comfo_fresh": "ComfoFresh (Air Distribution System)",
      "comfo_post": "ComfoPost",
      "leave_page_warning": "Are you sure you want to leave the page? The entered data will not be saved.",
      "overflow_technology": "Overflow Technology"
    },
    "bulk_amend": {
      "header": {
        "text": "Please check the information below and correct it if necessary.",
        "title": "Check and correct the data"
      }
    },
    "bulk_confirmed": {
      "button": {
        "primary": "Back to dashboard"
      },
      "header": {
        "text_plural": "Congratulations! The product registrations are succeeded. You will receive a confirmation mail. The registrations are now also visible in the dashboard.",
        "text_singular": "Congratulations! The product registration has succeeded. You will receive a confirmation email. The registration is now also visible in the dashboard.",
        "title_plural": "{count} of new registrations added",
        "title_singular": "{count} new registration added"
      }
    },
    "bulk_enter": {
      "button": {
        "help": "Need help?",
        "primary": "Next",
        "secondary": "Manual input"
      },
      "header": {
        "text": "Paste the fields '<b>'serial number, postal code and house number (incl. suffix)'</b>' from an excel or other database into this sheet. Alternatively, you can enter the data '<a class=\"text-red-400 bold\" href=\"/product-registrations/bulk/manual\">'manually'</a>'.",
        "title": "Copy and paste"
      },
      "modal": {
        "text": "On this page you can register several products at once. You can use an existing table as a base document in which you have stored the data of the registrations. For example, this can be a table in an Excel file or Google Spreadsheets. In order to start registering multiple products at the same time, we need the following information to make the registration process as easy as possible: serial number, postal code and house number. Proceed as follows: '<ol><li>'Open the source file in which you have kept the product registrations. This may be an Excel file, or another type of file in which your data is stored in table form.'</li><li>'Select the columns in your Excel file that contain the serial numbers, postal codes and house numbers. Note: Copy only these three columns. Postal code and house number should be two separate columns and the street name can be omitted. Other columns should also be omitted.'</li><li>'Paste the copied columns into the yellow input field. Please note: Do not modify the data on this page any further. You can easily edit the data entered later in the process. Don't have an Excel file to copy from? Then choose manual input.'</li></ol>'",
        "title": "How to copy and paste an Excel file"
      },
      "placeholder": {
        "house_number": "house number",
        "postal_code": "postal code",
        "serial_number": "serial number"
      }
    },
    "bulk_input_table": {
      "button": {
        "add_row": "Add row",
        "add_row_mobile": "Add registration",
        "delete_row_mobile": "Delete registration",
        "primary": "Next"
      },
      "error": {
        "serial_number_invalid": "This serial number is not valid",
        "serial_number_registered": "The serial number has already been registered"
      },
      "remove_row_modal": {
        "button": {
          "primary": "Delete",
          "secondary": "Cancel"
        },
        "text": "The data you have entered in this row will be lost. This action cannot be undone.",
        "title": "Are you sure?"
      },
      "table": {
        "city": "Town",
        "dropdown_extra": "extra",
        "dropdown_extras": "extras",
        "dropdown_select": "Select",
        "extra": "Extra",
        "extras": "Extras",
        "house_number": "No.",
        "postal_code": "Postal Code",
        "registered_serial_tooltip": "This serial number cannot be processed as the product is already registered.",
        "serial_number": "Serial Number",
        "street": "Street",
        "type": "Type"
      }
    },
    "bulk_manual": {
      "header": {
        "text": "Enter the registrations line per line. After entering the serial number the product type is automatically retrieved. After entering the postal code and house number, the street name and city are automatically entered. In addition, it is possible to select any extras.",
        "title": "Enter manually"
      }
    },
    "bulk_prepare": {
      "button": {
        "primary": "Next"
      },
      "header": {
        "text": "Link the correct data to the corresponding field. A maximum of 5 rows will be shown.",
        "title": "Select the correct columns"
      },
      "table": {
        "house_number": "House number",
        "irrelevant": "Irrelevant",
        "placeholder": "Make a choice",
        "postal_code": "Postal code",
        "serial_number": "Serial number"
      }
    },
    "comfofan_silent": {
      "confirmed": {
        "confirmation": "You will receive a confirmation email shortly.",
        "link": "'<a class=\"base-button base-button--is-gray\" href=\"https://www.zehnder.nl/gezondheid\" target=\"_blank\" rel=\"noopener noreferrer\">'Read all about a healthy home! '</a>'",
        "text": "The registration of your ComfoFan Silent was successful. You will soon receive a confirmation e-mail. ",
        "text_additional": "With this product, you have chosen a healthy indoor climate. Are you curious about the effects of ventilation on a healthy home? (Link is in Dutch)",
        "title": "Thank you for registering!"
      },
      "view": {
        "button": "Start registration",
        "text": "Continue the registration by completing the data. ",
        "title": "Register your ComfoFan Silent now"
      }
    },
    "comfofan_silent_find": {
      "button": "Start registration",
      "modal": {
        "button": "Where can I find the serial number?",
        "items": {
          "one": {
            "steps": {
              "one": {
                "title": "Remove the quickguide from the cover."
              },
              "two": {
                "title": "Read the serial number off the type plate."
              }
            },
            "title": "ComfoFan Silent"
          }
        },
        "text": "The location of the serial number differs per type of product. Indicate which product you want to register to find the steps below.",
        "title": "Where can I find the serial number?"
      },
      "placeholder": "For example 903279002",
      "text": "The serial number is necessary to register your product. Can't find it? Click on 'Where can I find the serial number?'",
      "title": "Enter the serial number"
    },
    "create_account": {
      "error": {
        "button": "Request a new link",
        "text": "The account could not be created. Please try again.",
        "title": "Something went wrong"
      },
      "idle": {
        "button": "Create an account",
        "consent": "I agree to the '<a href=\"https://www.zehnder.nl/zehnderzeker/registratievoorwaarden\" target=\"_blank\">'product registration terms'</a>'",
        "form": {
          "address": {
            "label": "Street name*",
            "placeholder": "Enter your street name"
          },
          "companyName": {
            "label": "Company name",
            "placeholder": "Enter your company name"
          },
          "email": {
            "label": "Email*",
            "placeholder": "Enter your email"
          },
          "house_number": {
            "label": "House number*",
            "placeholder": "Enter your house number"
          },
          "locality": {
            "label": "City*",
            "placeholder": "Enter your city"
          },
          "postal_code": {
            "label": "Postal code*",
            "placeholder": "Enter your postal code"
          },
          "telephone": {
            "label": "Phone number",
            "placeholder": "Enter your phone number"
          }
        },
        "text": "Enter your business details here to access the registrations of your installation company.",
        "title": "Create account"
      },
      "running": {
        "title": "Loading..."
      },
      "success": {
        "text": "Your account has been created. You will receive an email on {email} with a link to log in.",
        "title": "Your account has been created"
      },
      "user_exists": "This email address is already registered, try logging in '<a class=\"text-red-400 bold\" href=\"/login\">'here'</a>'."
    },
    "find": {
      "button": "Next",
      "faq_link": {
        "label": "Questions? Consult the FAQ (in Dutch)",
        "url": "https://www.zehnder.nl/zehnder-zeker-veelgestelde-vragen"
      },
      "modal": {
        "button": "Where can I find the serial number?",
        "items": {
          "one": {
            "steps": {
              "one": {
                "title": "Look at the cover of the display."
              },
              "two": {
                "title": "Read out the serial number from the label on the right side of the cover."
              }
            },
            "title": "ComfoAir E"
          },
          "three": {
            "steps": {
              "one": {
                "title": "Look at the front of the ComfoAir Standard."
              },
              "two": {
                "title": "Read out the serial number from the label on the top of the unit."
              }
            },
            "title": "ComfoAir Standard"
          },
          "title": "What type would you like to register?",
          "two": {
            "steps": {
              "one": {
                "title": "Open the ComfoAir Q black cover."
              },
              "three": {
                "title": "Read out the serial number from the label under the power supply."
              },
              "two": {
                "title": "Remove the grey slider on top of the device by pulling it towards you."
              }
            },
            "title": "ComfoAir Q"
          }
        },
        "text": "The location of the serial number differs per type of product. Indicate which product you want to register to find the steps below.",
        "title": "Where can I find the serial number?"
      },
      "not_found": "Sorry, the serial number cannot be registered. Check where to find the serial number below, or '<a href=\"https://www.zehnder.nl/zehnder-zeker-veelgestelde-vragen\" class=\"underline\" target=\"_blank\">take a look at the frequently asked questions</a>'.",
      "placeholder": "For example 903279002",
      "text": "And collect points as an installer for the Partner for Life program or receive 50% rebate as a resident on a set of Original Zehnder Filters in our webshop.",
      "title": "Enter the serial number here"
    },
    "index": {
      "button": {
        "create_account": "Create account",
        "dashboard": "Go to dashboard",
        "login": "Login for installers",
        "register": "Register product"
      },
      "text": "and enjoy the benefits. As an installer and consumer, you will enjoy the comfort of good ventilation.",
      "title": "Register your Zehnder ventilation unit"
    },
    "login": {
      "error": {
        "button": "Request a new link",
        "text": "Click on \"Request a new link\" below to receive a new link.",
        "title": "Something went wrong"
      },
      "idle": {
        "button": "Log in",
        "form": {
          "email": {
            "label": "Email",
            "placeholder": "Enter your email"
          }
        },
        "text": "Enter your email address to log in and manage your registrations.",
        "title": "Log in"
      },
      "not_found": {
        "button": "Go back",
        "text": "The email address {email} could not be found.",
        "title": "Email address not found"
      },
      "running": {
        "title": "Loading..."
      },
      "success": {
        "text": "You will receive an email on {email} containing a link to log in.",
        "title": "Check your email"
      }
    },
    "login_installer": "Log in",
    "maintenance_mode": {
      "system_not_available": "Due to maintenance work, the application will be limited or unavailable. Apologies for the inconvenience."
    },
    "nav_bar": {
      "home": "Home",
      "product_registrations": "Dashboard",
      "users": "Users"
    },
    "pincode": {
      "error": "This does not appear to be a valid code"
    },
    "product-registration-details": {
      "button": {
        "modify": "Modify"
      },
      "label": {
        "address": "Address",
        "date": "Registration date",
        "description": "Description",
        "postal_code_locality": "Postal code and city",
        "serial_number": "Serial number"
      },
      "locality_unknown": "locality unknown",
      "model_unknown": "Model unknown",
      "postal_code_unknown": "Postal code unknown",
      "unknown": "Unknown",
      "value_status": {
        "finalized": "Finished",
        "syncing": "Being processed",
        "unfinalized": "Unfinished"
      }
    },
    "product-registration-update": {
      "button": {
        "save": "Save registration"
      },
      "installation_address": {
        "placeholder": "Street & house number"
      },
      "installation_locality": {
        "placeholder": "For example Zwolle"
      },
      "installation_postal_code": {
        "placeholder": "For example 8012 AB"
      },
      "installation_remarks": {
        "placeholder": "E.g. building or project reference"
      },
      "label": {
        "address": "Address",
        "date": "Registration date",
        "description": "Description",
        "locality": "City",
        "postal_code": "Postal code",
        "serial_number": "Serial number",
        "type": "Type"
      }
    },
    "product_data": {
      "accessoires": {
        "combi_flow": "CombiFlow",
        "comfo_connect": "ComfoConnect (Gateway)",
        "comfo_cool": "ComfoCool/ComfoClime (Comfort unit)",
        "comfo_fond_l": "ComfoFond-L Q (Sub-soil heat exchanger)",
        "comfo_fresh": "ComfoFresh (Air Distribution System)",
        "comfo_post": "ComfoPost",
        "overflow_technology": "Overflow Technology"
      }
    },
    "product_registrations": {
      "confirmed": {
        "consumer": {
          "confirmation": "You will receive a confirmation email shortly.",
          "link": "'<a class=\"base-button base-button--is-gray\" href=\"https://www.zehnder.nl/nl/inspiratie/over-ventilatie/tips-voor-een-goedwerkend-systeem\" target=\"_blank\" rel=\"noopener noreferrer\">'Read more (in Dutch)'</a>'",
          "text": "The registration of your {productTitle} was successful. You will receive a confirmation email including further details shortly.",
          "text_additional": "Do you want to ensure that your ventilation system works optimally? Discover how to keep your ventilation system in top condition and how Zehnder can support you in achieving that.",
          "title": "Thank you for registering!",
          "warranty": ""
        },
        "installer": {
          "button": "Manage your registration(s)",
          "confirmation": "You will receive a confirmation email shortly.",
          "link_text": "Read more about this product",
          "postfix_complete_registration": "Your warranty is extended.",
          "postfix_incomplete_registration": "Please add the address to complete the registration.",
          "text": "Thank you for registering the {productTitle}.",
          "title": "Thank you for registering!",
          "warranty": "Your warranty is valid until:",
          "warranty_not_found": "There is no warranty date known. Please contact us."
        }
      },
      "view": {
        "back_to_link": "Back to overview"
      }
    },
    "product_registrations_create": {
      "accessoires": {
        "meta": "Zehnder",
        "text": "Please indicate below if the system is equipped with add-ons. If this is not the case, or when you are not sure, then click on 'next'.",
        "title": "Which add-ons are installed with this system?"
      },
      "end_user_company_name": {
        "option_manual": "By a company",
        "option_self_installed": "I don't know",
        "option_unknown": "By myself",
        "placeholder": "For example Zehnder Installation",
        "text": "Enter the name of the company that installed your Zehnder product and the city this company is based, if known.",
        "title": "By whom was your Zehnder product installed?"
      },
      "end_user_email": {
        "consent": "I agree to the '<a href=\"https://www.zehnder.nl/zehnderzeker/registratievoorwaarden\" target=\"_blank\">'product registration terms'</a>'",
        "placeholder": "jan.janssen@zehnder.nl",
        "text": "Please enter a business email address, if applicable.",
        "title": "Wat is your email?"
      },
      "end_user_first_name": {
        "placeholder": "Jan"
      },
      "end_user_gender": {
        "gender": {
          "female": "Madam",
          "male": "Sir"
        },
        "text": "",
        "title": "How may we address you?"
      },
      "end_user_installation_address": {
        "placeholder": "Street & house number",
        "text": "Specify the address of where the system is installed.",
        "title": "At which address is the system installed?"
      },
      "end_user_last_name": {
        "placeholder": "Janssen"
      },
      "end_user_name": {
        "text": "We will use your name for communication about your ventilation product.",
        "title": "What is your name?"
      },
      "end_user_reward_date": {
        "has_registered_consumer": "This product has been registered before. Therefore, Zehnder Original Filters have previously been handed out for this product. It is not possible to request a second free set of filters. Please proceed to the next step to resume the registration process or visit the '<a class=\"text-red-400 bold\" target=\"_blank\" href=\"https://nl.zehnder-filtershop.com/en\">'webshop'</a>' to purchase Zehnder Original Filters.",
        "option": {
          "asap": "Within 3 days",
          "half_year": "In 3 months",
          "month": "In 1 month",
          "week": "In 1 week"
        },
        "text": "The filters will be sent to '<span class=\"text-gray-700 bold\">'{address}'</span>'.",
        "title": "When would you like to receive your set of filters?"
      },
      "error": {
        "button": "Try again",
        "text": "We were unable to register your product. Please try again.",
        "title": "Something went wrong while saving"
      },
      "installation_address": {
        "empty": "No results",
        "loading": "Street names are loading...",
        "placeholder": "Street",
        "search_street": "Search a street",
        "text": "Specify the address of where the system is installed. Do you not know the address, or is it not yet known? Please click on the button below.",
        "title": "At which address is the system installed?"
      },
      "installation_company_name": {
        "placeholder": "For example Zehnder Installation",
        "text": "Enter the name of the company you work for and the name of the city the company is based.",
        "title": "Which installation company do you work for?"
      },
      "installation_house_number": {
        "placeholder": "House no."
      },
      "installation_locality": {
        "placeholder": "City",
        "text": "",
        "title": "In which city is the system installed?"
      },
      "installation_postal_code": {
        "placeholder": "Postal code",
        "text": "",
        "title": "What is the postal code?"
      },
      "installation_remarks": {
        "placeholder": "Example: a building or project reference.",
        "text": "Caution! The registration is only complete when the correct and permanent address has been provided. By entering your own reference, no reward can be provided. As soon as the address is known, you can let us know to claim your reward.",
        "title": "Give a description of the location."
      },
      "installation_search_again": "Search again",
      "installer_company_city": {
        "placeholder": "For example Zwolle",
        "text": "Enter the location from which you are working for this company.",
        "title": "In which city is your company based?"
      },
      "installer_company_email": {
        "placeholder": "jan.janssen@zehnder.nl",
        "text": "Please enter a business email address, if applicable.",
        "title": "What is your email?"
      },
      "installer_completed": {
        "placeholder": "Zehnder Installateurs BV",
        "text": "",
        "title": "The registration of your {productTitle} was successful. You will receive a confirmation email including further details shortly."
      },
      "installer_privacy": {
        "consent": "I agree to the '<a href=\"https://www.zehnder.nl/zehnderzeker/registratievoorwaarden\" target=\"_blank\">'product registration terms'</a>'",
        "text": "By agreeing to the terms and conditions, the registration will be submitted.",
        "title": "You're almost done!"
      },
      "navigation_button": {
        "next": "Next",
        "previous": "Previous",
        "skip": "I do not know the address"
      },
      "role": {
        "consumer": "Resident",
        "has_registered_installer": "This serial number has already been registered by an installer, hence a new registration to this serial number is not possible. Please check if it is the right serial number, or '<a href=\"/login\" class=\"bold\">'log in to the dashboard'</a>' to see if you have already registered this product.",
        "installer": "Installer",
        "text": "Indicate below whether you are a resident or an installer.",
        "title": "I register as?"
      },
      "subscribe_newsletter": {
        "consent": "I would like to subscribe to the Zehnder newsletter"
      }
    },
    "product_select": {
      "button_next": "Next",
      "button_prev": "Previous",
      "not_selectable": "This product cannot be registered as an installer.",
      "products": {
        "one": {
          "text": "Balanced ventilation with heat recovery (HRV).",
          "title": "ComfoAir Q, E or Standard"
        },
        "two": {
          "text": "Mechanical extract ventilation (MEV).",
          "title": "ComfoFan Silent"
        }
      },
      "text": "Select a product of the balanced ventilation or mechanical ventilation category.",
      "title": "What type of product do you want to register?",
      "wtw_not_available": "Due to technical problems we're currently unable to register balanced ventilation products. Please try again later."
    },
    "products": {
      "view": {
        "call_to_action": {
          "button": "Order new filters",
          "description": "Original air filters from Zehnder reduce the noise of the ventilation unit, have a longer lifespan, and operate more efficiently.",
          "title": "Benefit from a healthy indoor climate with Zehnder original air filters."
        },
        "has_registration": {
          "button": "Register again",
          "end_user_mail": "Registered by:",
          "floating_link": "'<a target=''_blank'' href='{link}'>Detailed product information</a>'",
          "label": "Wasn't you?",
          "next": "Register a new resident",
          "text": "More product information can be found on '<a target=''_blank'' href='{link}'>zehnder.nl</a>'.",
          "title": "This {productTitle} has already been registered",
          "warranty_end": "Registered until:",
          "warranty_start": "Registered on:"
        },
        "has_registration_installer": {
          "button": "Go to dashboard",
          "text": "This serial number has already been registered by an installer, hence a new registration to this serial number is not possible. Please check if it is the right serial number, or '<a href=\"/login\">'check the dashboard'</a>' to see if you have already registered this product."
        },
        "no_registration": {
          "button": "Start registration",
          "text": "And collect points as an installer for the Partner for Life program or receive 50% rebate as a resident on a set of Original Zehnder Filters in our webshop.",
          "title": "Register your {productTitle} now"
        },
        "no_registration_installer": {
          "button": "Register as installer"
        },
        "not_found": {
          "button": "To starting page",
          "text": "No product was found for the serial number entered, please try again in 24 hours or visit the starting page.",
          "title": "Product not found"
        }
      }
    },
    "products_verify": {
      "text": "These are the last 4 digits of your serial number",
      "title": "What is the PIN number as stated on the QR-Code label?"
    },
    "profile": {
      "address": "Street and house number",
      "back_to_link": "Back to overview",
      "button": "Edit profile",
      "company_name": "Company name",
      "email": "Email",
      "first_name": "Given name",
      "last_name": "Surname",
      "locality": "City",
      "message_error": "An error occurred while saving your profile. Please try again in a bit.",
      "message_success": "Successfully updated your profile!",
      "phone": "Phone",
      "postal_code": "Postal code",
      "title": "My profile",
      "unknown": "Unknown"
    },
    "region_switch": {
      "approve": "Confirm your choice",
      "cancel": "Cancel",
      "country_select": "Choose a country",
      "language_select": "Choose a language",
      "text": "",
      "text_language": "",
      "title": "Choose a country and language",
      "title_language": "Choose a language"
    },
    "registrations_list": {
      "header": {
        "date": "Registration date",
        "location": "Location",
        "postal_code": "Postal code",
        "serial_number": "Serial number",
        "status": "Registration status",
        "type": "Type"
      },
      "no_registrations": "No product registrations found.",
      "no_registrations_filtered": "No product registrations found with given criteria.",
      "type_unknown": "Unknown",
      "value_status": {
        "finalized": "Finished",
        "syncing": "Being processed",
        "unfinalized": "Unfinished"
      }
    },
    "registrations_panel": {
      "button": {
        "filter": "Filter",
        "map": "Map",
        "new_registration": "New registration"
      },
      "filters": {
        "button": {
          "clear": "Remove filters"
        },
        "date": {
          "label": "Registration date"
        },
        "search": {
          "label": "Search",
          "placeholder": "Address or serial number"
        },
        "type": {
          "count_suffix": "selected",
          "label": "Type"
        }
      },
      "tab_all": "All",
      "tab_finalized": "Finished",
      "tab_unfinalized": {
        "default": "Unfinished",
        "mobile": "Unfinished"
      }
    },
    "support_links": {
      "filter_shop": "Order filters and accessories",
      "product_info": "Product information",
      "service": "Service and support",
      "title": "Maintenance and service"
    },
    "toaster": {
      "address_not_available": "No address can be found for this postal code and house number comination.",
      "addresses_not_available": "The addresses could not automatically be retrieved. Click here to retry, or contact us if the problem persists.",
      "create_registration_failed": "The registration was unsuccessful. Click here to retry, or contact us if the problem persists.",
      "product_not_available": "No product can be found with this serial number.",
      "products_not_available": "The products could not automatically be retrieved. Click here to retry, or contact us if the problem persists."
    },
    "user_navigation": {
      "dashboard": "Dashboard",
      "link": "Go to zehnder.nl",
      "link_title": "Zehnder.nl",
      "link_url": "https://www.zehnder.nl",
      "logout": "Log out",
      "my_profile": "My profile",
      "product_registrations": "Productregistrations",
      "switch_language": "Naar Nederlands"
    },
    "validations": {
      "account": {
        "address_empty": "Street name cannot be blank",
        "email_empty": "Email cannot be blank",
        "email_invalid": "Please enter a valid email address",
        "house_number_empty": "House number cannot be blank",
        "locality_empty": "City cannot be blank",
        "postal_code_empty": "Postal code cannot be blank",
        "postal_code_invalid": "Please enter a valid Dutch postal code like \"8021 AB\""
      },
      "bulk": {
        "city_empty": "Fill in the city here",
        "general_error": "Invalid input, please correct the highlighted fields",
        "house_number_empty": "Please enter the house number here",
        "postal_code_empty": "Please enter the postal code here",
        "postal_code_invalid": "Please enter a valid Dutch postal code, for example \"8021 AB\"",
        "serial_number_empty": "Please enter the serial number here in order to proceed",
        "serial_number_invalid": "This is an invalid serial number",
        "serial_number_registered": "This serial number has already been registered",
        "serial_number_unknown": "No product can be found with this serial number",
        "street_empty": "Fill in the street here"
      },
      "comfofan_silent": {
        "batch_number_empty": "Serial number cannot be blank.",
        "batch_number_length": "Incorrect serial number. The serial number consists of 8 - 12 digits.",
        "batch_number_type": "A serial number consists only of numbers."
      },
      "login": {
        "email_empty": "Email cannot be blank",
        "email_invalid": "Please enter a valid email address"
      },
      "product": {
        "serial_number_empty": "Serial number cannot be blank"
      },
      "product_registration": {
        "email_empty": "Email cannot be blank",
        "email_invalid": "Please enter a valid email address",
        "postal_code_empty": "Postal code cannot be blank",
        "postal_code_invalid": "Please enter a valid Dutch postal code like \"8021 AB\""
      },
      "product_registrations_update": {
        "address_empty": "Address cannot be blank",
        "description_empty": "Description cannot be blank",
        "locality_empty": "City cannot be blank",
        "postal_code_empty": "Postal code cannot be blank",
        "postal_code_invalid": "Please enter a valid Dutch postal code like \"8021 AB\""
      },
      "profile": {
        "postal_code_invalid": "Please enter a valid Dutch postal code like \"8021 AB\""
      }
    },
    "version": {
      "back_link_text": "Back to home screen",
      "title": "App info",
      "version_prefix": "Version"
    }
  }], ["fr-be", {
    "app_title": "Zehnder - Enregistrement du produit",
    "bulk": {
      "combi_flow": "CombiFlow",
      "comfo_connect": "ComfoConnect (Gateway)",
      "comfo_cool": "ComfoCool/ComfoClime (Unité de confort)",
      "comfo_fond_l": "ComfoFond-L Q (Echangeur de chaleur géothermique)",
      "comfo_fresh": "ComfoFresh (Système de distribution d'air)",
      "comfo_post": "ComfoPost",
      "leave_page_warning": "Êtes-vous sûr de vouloir quitter la page? Les données saisies ne seront pas enregistrées.",
      "overflow_technology": "Overflow Technology"
    },
    "bulk_amend": {
      "header": {
        "text": "Veuillez vérifier les informations ci-dessous et les corriger si nécessaire.",
        "title": "Vérifiez et corrigez les données"
      }
    },
    "bulk_confirmed": {
      "button": {
        "primary": "Retourner à dashboard"
      },
      "header": {
        "text_plural": "Félicitations! L'enregistrement du produit a réussi. Vous allez recevoir un mail de confirmation. Les enregistrements sont maintenant visibles dans le tableau de bord.",
        "text_singular": "Félicitations! L'enregistrement du produit a réussi. Vous recevrez un e-mail de confirmation. L'enregistrement est maintenant également visible dans le tableau de bord.",
        "title_plural": "{count} de nouveaux enregistrements ajoutés",
        "title_singular": "{count} nouvel enregistrement ajouté"
      }
    },
    "bulk_enter": {
      "button": {
        "help": "Besoin d'aide?",
        "primary": "Suivant",
        "secondary": "Entrer manuellement"
      },
      "header": {
        "text": "Coller les champs'<b>'Numéro de série, code postal et numéro de maison (y compris le suffixe)'</b>' d'une base de données Excel ou autre dans cette feuille. Vous pouvez également saisir les données '<a class=\"text-red-400 bold\" href=\"/product-registrations/bulk/manual\">'manuellement'</a>",
        "title": "Copier et coller"
      },
      "modal": {
        "text": "Sur cette page, vous pouvez enregistrer plusieurs produits à la fois. Vous pouvez utiliser un tableau existant comme document de base dans lequel vous avez stocké les données des enregistrements. Par exemple, il peut s'agir d'un tableau dans un fichier Excel ou dans une feuille de calcul Google. Afin de commencer à enregistrer plusieurs produits en même temps, nous avons besoin des informations suivantes pour faciliter au maximum le processus d'enregistrement : numéro de série, code postal et numéro de maison. Procédez comme suit: '<ol><li>'Ouvrez le fichier source dans lequel vous avez conservé les enregistrements de produits. Il peut s'agir d'un fichier Excel ou d'un autre type de fichier dans lequel vos données sont enregistrées sous forme de tableau.'</li><li>'Sélectionnez les colonnes de votre fichier Excel qui contiennent les numéros de série, les codes postaux et les numéros de maison. Remarque : ne copiez que ces trois colonnes. Le code postal et le numéro de maison doivent être deux colonnes distinctes et le nom de la rue peut être omis. Les autres colonnes doivent également être omises.'</li><li>'Collez les colonnes copiées dans le champ de saisie jaune. Remarque : ne modifiez plus les données de cette page. Vous pourrez facilement modifier les données saisies plus tard dans le processus. Vous n'avez pas de fichier Excel à copier ? Choisissez alors la saisie manuelle.'</li></ol>'",
        "title": "Comment copier et coller un fichier Excel"
      },
      "placeholder": {
        "house_number": "Numéro de maison",
        "postal_code": "Code postal",
        "serial_number": "Numéro de série"
      }
    },
    "bulk_input_table": {
      "button": {
        "add_row": "Ajouter une ligne",
        "add_row_mobile": "Ajouter un enregistrement",
        "delete_row_mobile": "Supprimer un enregistrement",
        "primary": "Suivant"
      },
      "error": {
        "serial_number_invalid": "Ce numéro de série n'est pas valide",
        "serial_number_registered": "Ce numéro de série numéro de série a déjà été enregistré"
      },
      "remove_row_modal": {
        "button": {
          "primary": "Supprimer",
          "secondary": "Annuler"
        },
        "text": "Les données que vous avez saisies dans cette ligne seront perdues. Cette action ne peut pas être annulée.",
        "title": "Vous êtes sûr ?"
      },
      "table": {
        "city": "Ville",
        "dropdown_extra": "Extra",
        "dropdown_extras": "Extras",
        "dropdown_select": "Sélectionnez",
        "extra": "Extra",
        "extras": "Extras",
        "house_number": "Numéro de maison",
        "postal_code": "Code postal",
        "registered_serial_tooltip": "Ce numéro de série ne peut pas être traité car le produit est déjà enregistré.",
        "serial_number": "Numéro de série",
        "street": "Rue",
        "type": "Type"
      }
    },
    "bulk_manual": {
      "header": {
        "text": "Saisissez les enregistrements ligne par ligne. Après avoir saisi le numéro de série, le type de produit est automatiquement récupéré. Après avoir saisi le code postal et le numéro de maison, le nom de la rue et la ville sont automatiquement saisis. En outre, il est possible de sélectionner des extras.",
        "title": "Saisir manuellement"
      }
    },
    "bulk_prepare": {
      "button": {
        "primary": "Suivant"
      },
      "header": {
        "text": "Associez les données correctes au champ correspondant. Un maximum de 5 lignes sera affiché",
        "title": "Sélectionnez les bonnes colonnes"
      },
      "table": {
        "house_number": "Numéro de maison",
        "irrelevant": "Non pertinent",
        "placeholder": "Faites un choix",
        "postal_code": "Code postal",
        "serial_number": "Numéro de série"
      }
    },
    "comfofan_silent": {
      "confirmed": {
        "confirmation": "Vous recevrez bientôt un e-mail de confirmation.",
        "link": "'<a class=\"base-button base-button--is-gray\" href=\"https://www.zehnder.be/fr/inspiration/sante\" target=\"_blank\" rel=\"noopener noreferrer\">'Lisez tout sur une maison saine!'</a>'",
        "text": "L'enregistrement de votre ComfoFan Silent a été effectué avec succès. Vous recevrez bientôt un e-mail de confirmation.",
        "text_additional": "Avec ce produit, vous avez choisi un climat intérieur sain. Vous êtes curieux de connaître les effets de la ventilation sur une maison saine ? (Le lien est en néerlandais)",
        "title": "Merci pour l'enregistrement !"
      },
      "view": {
        "button": "Commencer l'enregistrement",
        "text": "Continuez l'enregistrement en complétant les données.",
        "title": "Enregistrez votre ComfoFan Silent maintenant"
      }
    },
    "comfofan_silent_find": {
      "button": "Commencer l'enregistrement",
      "modal": {
        "button": "Où puis-je trouver le numéro de série ?",
        "items": {
          "one": {
            "steps": {
              "one": {
                "title": "Retirez le guide rapide du couvercle."
              },
              "two": {
                "title": "Lisez le numéro de série sur la plaque signalétique."
              }
            },
            "title": "ComfoFan Silent"
          }
        },
        "text": "L'emplacement du numéro de série diffère selon le type de produit. Indiquez le produit que vous souhaitez enregistrer pour trouver les étapes ci-dessous.",
        "title": "Où puis-je trouver le numéro de série ?"
      },
      "placeholder": "Par exemple 903279002",
      "text": "Le numéro de série est nécessaire pour enregistrer votre produit. Vous ne le trouvez pas? Cliquez sur 'Où puis-je trouver le numéro de série?'",
      "title": "Saisir le numéro de série"
    },
    "create_account": {
      "error": {
        "button": "Demander un nouveau lien",
        "text": "Le compte n'a pas pu être créé. Veuillez réessayer.",
        "title": "Un problème est survenu"
      },
      "idle": {
        "button": "Créer un compte",
        "consent": "Je suis d'accord avec le '<a href=\"https://zehnder.be/fr/services-et-connaissances/service/montage-et-utilisation/outils-et-apps/enregistrement-du-produit/conditions-denregistrement\" target=\"_blank\">'conditions d'enregistrement des produits'</a>'",
        "form": {
          "address": {
            "label": "Nom de la rue*",
            "placeholder": "Entrez le nom de votre rue"
          },
          "companyName": {
            "label": "Nom de l'entreprise",
            "placeholder": "Saisissez le nom de votre entreprise"
          },
          "email": {
            "label": "E-mail*",
            "placeholder": "Entrez votre e-mail"
          },
          "house_number": {
            "label": "Numéro de maison*",
            "placeholder": "Entrez votre numéro de maison"
          },
          "locality": {
            "label": "Ville*",
            "placeholder": "Entrez votre ville"
          },
          "postal_code": {
            "label": "Code postal*",
            "placeholder": "Entrez votre Code postal"
          },
          "telephone": {
            "label": "Numéro de téléphone",
            "placeholder": "Entrez votre numéro de téléphone"
          }
        },
        "text": "Saisissez ici les coordonnées de votre entreprise pour accéder aux enregistrements de votre société d'installation.",
        "title": "Créer un compte"
      },
      "running": {
        "title": "Chargement..."
      },
      "success": {
        "text": "Votre compte a été créé. Vous allez recevoir un email sur {email} avec un lien pour vous connecter.",
        "title": "Votre compte a été créé"
      },
      "user_exists": "Cette adresse e-mail est déjà enregistrée, essayez de vous connecter'<a class=\"text-red-400 bold\" href=\"/login\">'ici'</a>'."
    },
    "find": {
      "button": "Suivant",
      "faq_link": {
        "label": "Des questions ? Consultez la FAQ",
        "url": "https://www.zehnder.be/fr/services-et-connaissances/service/montage-et-utilisation/outils-et-apps/enregistrement-du-produit/questions-frequentes"
      },
      "modal": {
        "button": "Où puis-je trouver le numéro de série ?",
        "items": {
          "one": {
            "steps": {
              "one": {
                "title": "Regardez le couvercle de l'écran."
              },
              "two": {
                "title": "Lisez le numéro de série sur l'étiquette située sur le côté droit du couvercle."
              }
            },
            "title": "ComfoAir Pro"
          },
          "three": {
            "steps": {
              "one": {
                "title": "Regardez l'avant du ComfoD."
              },
              "two": {
                "title": "Lisez le numéro de série sur l'étiquette située sur le dessus de l'appareil."
              }
            },
            "title": "ComfoD"
          },
          "title": "Quel type de produit souhaitez-vous enregistrer ?",
          "two": {
            "steps": {
              "one": {
                "title": "Ouvrez le couvercle noir du ComfoAir Q."
              },
              "three": {
                "title": "Lisez le numéro de série sur l'étiquette située sous le bloc d'alimentation."
              },
              "two": {
                "title": "Retirez le curseur gris situé sur le dessus de l'appareil en le tirant vers vous."
              }
            },
            "title": "ComfoAir Q"
          }
        },
        "text": "L'emplacement du numéro de série diffère selon le type de produit. Indiquez le produit que vous souhaitez enregistrer pour trouver les étapes ci-dessous.",
        "title": "Où puis-je trouver le numéro de série ?"
      },
      "not_found": "Désolé, le numéro de série ne peut pas être enregistré. Vérifiez où trouver le numéro de série ci-dessous, ou '<a href=\"https://www.zehnder.be/fr/services-et-connaissances/service/montage-et-utilisation/outils-et-apps/enregistrement-du-produit/questions-frequentes\" class=\"underline\" target=\"_blank\">jetez un coup d'œil aux questions fréquemment posées.</a>'.",
      "placeholder": "Par exemple 903279002",
      "text": "Et en tant qu'installateur, vous recevrez des points pour le programme partenaire ou en tant que résident, vous activerez le support à distance.",
      "title": "Entrez le numéro de série ici"
    },
    "index": {
      "button": {
        "create_account": "Créer un compte",
        "dashboard": "Aller au dashboard",
        "login": "Login pour les installateurs",
        "register": "Enregistrer le produit"
      },
      "text": "et profitez des avantages. En tant qu'installateur et consommateur, vous apprécierez le confort d'une bonne ventilation.",
      "title": "Enregistrez votre appareil de ventilation Zehnder"
    },
    "login": {
      "error": {
        "button": "Demander un nouveau lien",
        "text": "Cliquez sur \"Demander un nouveau lien\" ci-dessous pour recevoir un nouveau lien.",
        "title": "Un problème est survenu"
      },
      "idle": {
        "button": "Se connecter",
        "form": {
          "email": {
            "label": "Email",
            "placeholder": "Entrez votre email"
          }
        },
        "text": "Entrez votre adresse e-mail pour vous connecter et gérer vos inscriptions.",
        "title": "Se connecter"
      },
      "not_found": {
        "button": "Revenir en arrière",
        "text": "L'adresse électronique {email} n'a pas été trouvée.",
        "title": "Adresse électronique non trouvée"
      },
      "running": {
        "title": "Chargement..."
      },
      "success": {
        "text": "Vous recevrez un courriel sur {email} contenant un lien pour vous connecter.",
        "title": "Vérifiez votre adresse e-mail"
      }
    },
    "login_installer": "Se connecter",
    "maintenance_mode": {
      "system_not_available": "En raison de travaux de maintenance, l'application sera limitée ou indisponible. Toutes nos excuses pour la gêne occasionnée."
    },
    "nav_bar": {
      "home": "Accueil",
      "product_registrations": "Dashboard",
      "users": "Utilisateurs"
    },
    "pincode": {
      "error": "Ce numéro de série ne semble pas être valide"
    },
    "product-registration-details": {
      "button": {
        "modify": "Modifier"
      },
      "label": {
        "address": "Adresse",
        "date": "Date d'enregistrement",
        "description": "Description",
        "postal_code_locality": "Code postal et ville",
        "serial_number": "Numéro de série"
      },
      "locality_unknown": "Localisation inconnue",
      "model_unknown": "Modèle inconnu",
      "postal_code_unknown": "Code postal inconnu",
      "unknown": "Inconnu",
      "value_status": {
        "finalized": "Terminé",
        "syncing": "En cours de traitement",
        "unfinalized": "Non terminés"
      }
    },
    "product-registration-update": {
      "button": {
        "save": "Sauver l'enregistrement"
      },
      "installation_address": {
        "placeholder": "Rue et numéro de maison"
      },
      "installation_locality": {
        "placeholder": "Par exemple Mechelen"
      },
      "installation_postal_code": {
        "placeholder": "Par exemple 2800"
      },
      "installation_remarks": {
        "placeholder": "Par exemple Référence du bâtiment ou du projet"
      },
      "label": {
        "address": "Adresse",
        "date": "Date d'enregistrement",
        "description": "Description",
        "locality": "Ville",
        "postal_code": "Code postal",
        "serial_number": "Numéro de série",
        "type": "Type"
      }
    },
    "product_data": {
      "accessoires": {
        "combi_flow": "CombiFlow",
        "comfo_connect": "ComfoConnect (Gateway)",
        "comfo_cool": "ComfoCool/ComfoClime (Unité de confort)",
        "comfo_fond_l": "ComfoFond-L Q (Échangeur de chaleur souterrain)",
        "comfo_fresh": "ComfoFresh (Système de distribution d'air)",
        "comfo_post": "ComfoPost",
        "overflow_technology": "Overflow Technology"
      }
    },
    "product_registrations": {
      "confirmed": {
        "consumer": {
          "confirmation": "Vous recevrez un e-mail de confirmation prochainement.",
          "link": "'<a class=\"base-button base-button--is-gray\" href=\"https://www.zehnder.be/fr/inspiration/sante\" target=\"_blank\" rel=\"noopener noreferrer\">'Lisez tout sur une maison saine !'</a>'",
          "text": "L'enregistrement de votre {productTitle} a été effectué avec succès. Vous recevrez sous peu un e-mail de confirmation contenant des informations complémentaires.",
          "text_additional": "Avec ce produit, vous avez choisi un climat intérieur sain. Vous êtes curieux de connaître les effets de la ventilation sur une maison saine (lien en néerlandais) ?",
          "title": "Merci de votre inscription !",
          "warranty": ""
        },
        "installer": {
          "button": "Gérer votre/vos enregistrement(s)",
          "confirmation": "Vous recevrez un e-mail de confirmation prochainement.",
          "link_text": "En savoir plus sur ce produit",
          "postfix_complete_registration": "Vos points sont attribués.",
          "postfix_incomplete_registration": "Veuillez ajouter l'adresse pour compléter l'enregistrement.",
          "text": "Merci d'avoir enregistré le {productTitle}.",
          "title": "Merci de vous inscrire !",
          "warranty": "Votre garantie est valable jusqu'au:",
          "warranty_not_found": "Il n'y a pas de date de garantie connue."
        }
      },
      "view": {
        "back_to_link": "Retour à la vue d'ensemble"
      }
    },
    "product_registrations_create": {
      "accessoires": {
        "meta": "Zehnder",
        "text": "Veuillez indiquer ci-dessous si le système est équipé d'add-ons. Si ce n'est pas le cas, ou si vous n'êtes pas sûr, cliquez sur 'suivant'.",
        "title": "Quels modules complémentaires sont installés avec ce système ?"
      },
      "end_user_company_name": {
        "option_manual": "Par une société",
        "option_self_installed": "Par moi-même",
        "option_unknown": "Je ne sais pas",
        "placeholder": "Par exemple, installation de Zehnder",
        "text": "Saisissez le nom de l'entreprise qui a installé votre produit Zehnder et la ville où elle est basée, si vous les connaissez.",
        "title": "Par qui votre produit Zehnder a-t-il été installé ?"
      },
      "end_user_email": {
        "consent": "J'accepte le '<a href=\"https://zehnder.be/fr/services-et-connaissances/service/montage-et-utilisation/outils-et-apps/enregistrement-du-produit/conditions-denregistrement\" target=\"_blank\">'conditions d'enregistrement du produit'</a>'",
        "placeholder": "philip.lambert@zehnder.be",
        "text": "Veuillez saisir une adresse électronique professionnelle, le cas échéant.",
        "title": "Quelle est votre adresse e-mail ?"
      },
      "end_user_first_name": {
        "placeholder": "Philip"
      },
      "end_user_gender": {
        "gender": {
          "female": "Madame",
          "male": "Monsieur"
        },
        "text": "",
        "title": "Comment pouvons-nous nous adresser à vous ?"
      },
      "end_user_installation_address": {
        "placeholder": "Rue et numéro de maison",
        "text": "Indiquez l'adresse du lieu où le système est installé.",
        "title": "A quelle adresse le système est-il installé ?"
      },
      "end_user_last_name": {
        "placeholder": "Lambert"
      },
      "end_user_name": {
        "text": "Nous utiliserons votre nom pour communiquer sur votre produit de ventilation.",
        "title": "Quel est votre nom ?"
      },
      "end_user_reward_date": {
        "has_registered_consumer": "Ce produit a déjà été enregistré. Par conséquent, des filtres d'origine Zehnder ont déjà été distribués pour ce produit. Il n'est pas possible de demander un deuxième jeu de filtres gratuit. Veuillez passer à l'étape suivante pour reprendre le processus d'enregistrement ou visiter'<a class=\"text-red-400 bold\" target=\"_blank\" href=\"https://be.zehnder-filtershop.com/fr\">'la boutique'</a>' en ligne pour acheter des filtres d'origine Zehnder.",
        "option": {
          "asap": "Dans un délai de 3 jours",
          "half_year": "Dans 3 mois",
          "month": "Dans 1 mois",
          "week": "Dans 1 semaine"
        },
        "text": "Les filtres seront envoyés à '<span class=\"text-gray-700 bold\">'{address}'</span>'.",
        "title": "Quand souhaitez-vous recevoir votre jeu de filtres ?"
      },
      "error": {
        "button": "Try again",
        "text": "We were unable to register your product. Please try again.",
        "title": "Something went wrong while saving"
      },
      "installation_address": {
        "empty": "Pas de résultats",
        "loading": "Rues de chargement...",
        "placeholder": "Rue",
        "search_street": "Rechercher une rue",
        "text": "Indiquez l'adresse à laquelle le système est installé. Vous ne connaissez pas l'adresse, ou elle n'est pas encore connue ? Veuillez cliquer sur le bouton ci-dessous.",
        "title": "À quelle adresse le système est-il installé ?"
      },
      "installation_company_name": {
        "placeholder": "Par exemple, Zehnder Installation",
        "text": "Saisissez le nom de l'entreprise pour laquelle vous travaillez et le nom de la ville où elle est implantée.",
        "title": "Pour quelle société d'installation travaillez-vous ?"
      },
      "installation_house_number": {
        "placeholder": "Numéro de maison"
      },
      "installation_locality": {
        "placeholder": "Ville",
        "text": "Ville",
        "title": "Dans quelle ville le système est-il installé ?"
      },
      "installation_postal_code": {
        "placeholder": "Code postal",
        "text": "Code postal",
        "title": "Quel est le code postal ?"
      },
      "installation_remarks": {
        "placeholder": "Exemple : une référence de bâtiment ou de projet.",
        "text": "Attention ! L'enregistrement n'est complet que lorsque l'adresse correcte et permanente a été fournie. Si vous indiquez votre propre référence, aucune récompense ne pourra être accordée. Dès que l'adresse est connue, vous pouvez nous le faire savoir pour réclamer votre récompense.",
        "title": "Donnez une description de l'emplacement."
      },
      "installation_search_again": "Chercher à nouveau",
      "installer_company_city": {
        "placeholder": "Par exemple Mechelen",
        "text": "Saisissez le lieu d'où vous travaillez pour cette société.",
        "title": "Dans quelle ville votre société est-elle basée ?"
      },
      "installer_company_email": {
        "placeholder": "philip.lambert@zehnder.be",
        "text": "Veuillez saisir une email professionnelle, le cas échéant.",
        "title": "Quelle est votre email ?"
      },
      "installer_completed": {
        "placeholder": "Zehnder Installation",
        "text": "",
        "title": "L'enregistrement de votre {productTitle} a été effectué avec succès. Vous recevrez sous peu un e-mail de confirmation contenant des informations complémentaires."
      },
      "installer_privacy": {
        "consent": "J'accepte les '<a href=\"https://zehnder.be/fr/services-et-connaissances/service/montage-et-utilisation/outils-et-apps/enregistrement-du-produit/conditions-denregistrement\" target=\"_blank\">'conditions d'enregistrement du produit'</a>'",
        "text": "En acceptant les termes et conditions, l'enregistrement sera soumis.",
        "title": "Vous avez presque terminé !"
      },
      "navigation_button": {
        "next": "Suivant",
        "previous": "Précédent",
        "skip": "Je ne connais pas l'adresse"
      },
      "role": {
        "consumer": "Résident",
        "has_registered_installer": "Ce numéro de série a déjà été enregistré par un installateur. Un nouvel enregistrement pour ce numéro de série n'est donc pas possible. Veuillez vérifier si c'est le bon numéro de série, ou '<a href=\"/login\" class=\"bold\">'connectez-vous au dashboard'</a>' pour voir si vous avez déjà enregistré ce produit.",
        "installer": "Installateur",
        "text": "Indiquez ci-dessous si vous êtes un résident ou un installateur.",
        "title": "Sous laquelle je m'inscris ?"
      },
      "subscribe_newsletter": {
        "consent": "Je souhaite m'abonner à la newsletter de Zehnder"
      }
    },
    "product_select": {
      "button_next": "Suivant",
      "button_prev": "Précédent",
      "not_selectable": "Ce produit ne peut pas être enregistré en tant qu'installateur.",
      "products": {
        "one": {
          "text": "Ventilation équilibrée avec récupération de chaleur.",
          "title": "ComfoAir Q, E or Standard"
        },
        "two": {
          "text": "Ventilation par extraction mécanique.",
          "title": "ComfoFan Silent"
        }
      },
      "text": "Sélectionnez un produit de la catégorie ventilation équilibrée ou ventilation mécanique.",
      "title": "Quel type de produit voulez-vous enregistrer ?",
      "wtw_not_available": "En raison de problèmes techniques, nous ne sommes pas en mesure d'enregistrer les produits de ventilation équilibrée. Veuillez réessayer plus tard."
    },
    "products": {
      "view": {
        "call_to_action": {
          "button": "Commander des filtres",
          "description": "Les filtres à air d'origine de Zehnder réduisent le bruit de l'unité de ventilation, ont une durée de vie plus longue et fonctionnent de manière plus efficace.",
          "title": "Bénéficiez d'un climat intérieur sain avec les filtres à air d'origine de Zehnder."
        },
        "has_registration": {
          "button": "Enregistrer encore",
          "end_user_mail": "Enregistré par:",
          "floating_link": "'<a target=''_blank'' href='{link}'>Informations détaillées sur le produit</a>'",
          "label": "Ce n'était pas toi ?",
          "next": "Enregistrer un nouveau résident",
          "text": "Vous trouverez de plus d'informations sur les produits sur '<a target=''_blank'' href='{link}'>zehnder.be</a>'.",
          "title": "Ce {productTitle} est déjà enregistré",
          "warranty_end": "Enregistré jusqu'au:",
          "warranty_start": "Enregistré le:"
        },
        "has_registration_installer": {
          "button": "Aller au dashboard",
          "text": "Ce numéro de série a déjà été enregistré par un installateur, il n'est donc pas possible d'effectuer un nouvel enregistrement pour ce numéro de série. Veuillez vérifier s'il s'agit du bon numéro de série, ou bien '<a href=\"/login\">'vérifiez le dashboard'</a>' pour voir si vous avez déjà enregistré ce produit."
        },
        "no_registration": {
          "button": "Commencer l'enregistrement",
          "text": "Et en tant qu'installateur, vous recevrez des points pour le programme partenaire ou en tant que résident, vous activerez le support à distance.",
          "title": "Enregistrez votre {productTitle} maintenant"
        },
        "no_registration_installer": {
          "button": "Enregistrez en tant qu'installateur"
        },
        "not_found": {
          "button": "Vers la page d'accueil",
          "text": "Aucun produit n'a été trouvé pour le numéro de série saisi, veuillez réessayer dans 24 heures ou visitez la page d'accueil.",
          "title": "Produit non trouvé"
        }
      }
    },
    "products_verify": {
      "text": "Ce sont les 4 derniers chiffres de votre numéro de série.",
      "title": "Quel est le code PIN indiqué sur l'étiquette du QR-Code ?"
    },
    "profile": {
      "address": "Rue et numéro de maison",
      "back_to_link": "Retour à la vue d'ensemble",
      "button": "Mettre à jour le profil",
      "company_name": "Nom de l'entreprise",
      "email": "Email",
      "first_name": "Prénom",
      "last_name": "Nom",
      "locality": "Ville",
      "message_error": "Une erreur s'est produite lors de l'enregistrement de votre profil. Veuillez réessayer dans quelques instants.",
      "message_success": "Votre profil a été mis à jour avec succès !",
      "phone": "Numéro de téléphon",
      "postal_code": "Code postal",
      "title": "Mon profil",
      "unknown": "Inconnu"
    },
    "region_switch": {
      "approve": "Confirmez votre choix",
      "cancel": "Annuler",
      "country_select": "Choisir le pays",
      "language_select": "Choisir la langue",
      "text": "",
      "text_language": "",
      "title": "Choisissez votre pays et votre langue",
      "title_language": "Choisissez votre langue"
    },
    "registrations_list": {
      "header": {
        "date": "Date d'enregistrement",
        "location": "Localisation",
        "postal_code": "Code postal",
        "serial_number": "Numéro de série",
        "status": "Statut de l'enregistrement",
        "type": "Type"
      },
      "no_registrations": "Aucun enregistrement de produit trouvé.",
      "no_registrations_filtered": "Aucun enregistrement de produit correspondant aux critères donnés n'a été trouvé.",
      "type_unknown": "Inconnu",
      "value_status": {
        "finalized": "Terminé",
        "syncing": "En cours de traitement",
        "unfinalized": "Non terminés"
      }
    },
    "registrations_panel": {
      "button": {
        "filter": "Filtrer",
        "map": "Carte",
        "new_registration": "Nouvel enregistrement"
      },
      "filters": {
        "button": {
          "clear": "Effacer les filtres"
        },
        "date": {
          "label": "Date d'enregistrement"
        },
        "search": {
          "label": "Recherche",
          "placeholder": "Adresse ou numéro de série"
        },
        "type": {
          "count_suffix": "Sélectionné",
          "label": "Type"
        }
      },
      "tab_all": "Tout",
      "tab_finalized": "Terminé",
      "tab_unfinalized": {
        "default": "Non terminés",
        "mobile": "Non terminés"
      }
    },
    "support_links": {
      "filter_shop": "Commander des filtres et des accessoires",
      "product_info": "Informations sur le produit",
      "service": "Service et assistance",
      "title": "Entretien et service"
    },
    "toaster": {
      "address_not_available": "Aucune adresse ne peut être trouvée pour cette combinaison de code postal et de numéro de maison.",
      "addresses_not_available": "Les adresses n'ont pas pu être récupérées automatiquement. Cliquez ici pour réessayer ou contactez-nous si le problème persiste.",
      "create_registration_failed": "L'inscription a échoué. Cliquez ici pour réessayer ou contactez-nous si le problème persiste.",
      "product_not_available": "Aucun produit ne peut être trouvé avec ce numéro de série.",
      "products_not_available": "Les produits n'ont pas pu être récupérés automatiquement. Cliquez ici pour réessayer ou contactez-nous si le problème persiste."
    },
    "user_navigation": {
      "dashboard": "Dashboard",
      "link": "Aller sur zehnder.be",
      "link_title": "Zehnder.be",
      "link_url": " https://www.zehnder.be/fr/",
      "logout": "Déconnexion",
      "my_profile": "Mon profil",
      "product_registrations": "Enregistrements de produits",
      "switch_language": "Naar Nederlands"
    },
    "validations": {
      "account": {
        "address_empty": "Le nom de la rue ne peut pas être vide",
        "email_empty": "L'e-mail ne peut pas être vide",
        "email_invalid": "Veuillez saisir une adresse e-mail valide",
        "house_number_empty": "Le numéro de maison ne peut être vide",
        "locality_empty": "La ville ne peut pas être vide",
        "postal_code_empty": "Code postal ne peut être vide",
        "postal_code_invalid": "Veuillez entrer un code postal belge valide comme \"2800\""
      },
      "bulk": {
        "city_empty": "Indiquez la ville ici",
        "general_error": "Entrée invalide, veuillez corriger les champs en surbrillance",
        "house_number_empty": "Veuillez saisir le numéro de maison ici",
        "postal_code_empty": "Veuillez saisir le code postal ici",
        "postal_code_invalid": "Veuillez entrer un code postal belge valide comme \"2800\"",
        "serial_number_empty": "Veuillez entrer le numéro de série ici afin de poursuivre",
        "serial_number_invalid": "Ce numéro de série n'est pas valide",
        "serial_number_registered": "Ce numéro de série a déjà été enregistré",
        "serial_number_unknown": "Aucun produit ne peut être trouvé avec ce numéro de série",
        "street_empty": "Remplissez la rue ici"
      },
      "comfofan_silent": {
        "batch_number_empty": "Le numéro de série ne peut pas être vide",
        "batch_number_length": "Le numéro de série est incorrect. Le numéro de série est composé de 8 à 12 chiffres",
        "batch_number_type": "Un numéro de série se compose uniquement de chiffres"
      },
      "login": {
        "email_empty": "E-mail ne peut pas être vide",
        "email_invalid": "Veuillez saisir une adresse électronique valide"
      },
      "product": {
        "serial_number_empty": "Numéro de série ne peut être vide"
      },
      "product_registration": {
        "email_empty": "E-mail ne peut pas être vide",
        "email_invalid": "Veuillez saisir une adresse e-mail valide",
        "postal_code_empty": "Code postal ne peut pas être vide",
        "postal_code_invalid": "Veuillez entrer un code postal belge valide comme \"2800\""
      },
      "product_registrations_update": {
        "address_empty": "Adresse ne peut être vide",
        "description_empty": "La description ne peut pas être vide",
        "locality_empty": "Ville ne peut être vide",
        "postal_code_empty": "Code postal ne peut être vide",
        "postal_code_invalid": "Veuillez entrer un code postal belge valide comme \"2800\""
      },
      "profile": {
        "postal_code_invalid": "Veuillez entrer un code postal belge valide comme \"2800\""
      }
    },
    "version": {
      "back_link_text": "Retour à l'écran d'accueil",
      "title": "Informations sur l'application",
      "version_prefix": "Version"
    }
  }], ["nl-be", {
    "app_title": "Zehnder - Productregistratie",
    "bulk": {
      "combi_flow": "CombiFlow",
      "comfo_connect": "ComfoConnect (Gateway)",
      "comfo_cool": "ComfoCool/ComfoClime (Comfortunit)",
      "comfo_fond_l": "ComfoFond-L Q (Aardwarmtewisselaar)",
      "comfo_fresh": "ComfoFresh (Luchtverdeelsysteem)",
      "comfo_post": "ComfoPost",
      "leave_page_warning": "Weet u zeker dat u de pagina wilt verlaten? De ingevoerde gegevens worden niet opgeslagen.",
      "overflow_technology": "Overflow Technology"
    },
    "bulk_amend": {
      "header": {
        "text": "Controleer of onderstaande gegevens kloppen en wijzig deze waar nodig.",
        "title": "Controleer en corrigeer de gegevens"
      }
    },
    "bulk_confirmed": {
      "button": {
        "primary": "Terug naar dashboard"
      },
      "header": {
        "text_plural": "Gefeliciteerd! De registraties van uw producten zijn gelukt. U ontvangt een mail ter bevestiging. De registraties zijn nu ook te zien in het dashboard.",
        "text_singular": "Gefeliciteerd! De registratie van uw product is gelukt. U ontvangt een mail ter bevestiging. De registratie is nu ook te zien in het dashboard.",
        "title_plural": "{count} nieuwe registraties toegevoegd",
        "title_singular": "{count} nieuwe registratie toegevoegd"
      }
    },
    "bulk_enter": {
      "button": {
        "help": "Hulp nodig?",
        "primary": "Volgende",
        "secondary": "Handmatig invoeren"
      },
      "header": {
        "text": "Plak de velden '<b>'serienummer, postcode en huisnummer (incl. toevoeging)'</b>' vanuit een excel of andere database hierin. U kunt ook de data '<a class=\"text-red-400 bold\" href=\"/product-registrations/bulk/manual\">'handmatig invoeren'</a>'.",
        "title": "Kopiëren en plakken"
      },
      "modal": {
        "text": "Via deze pagina registreert u meerdere producten tegelijk. Als basis hiervoor kan een bestaande tabel gebruikt worden waarin de gegevens van de registraties zijn opgeslagen. Dit kan bijvoorbeeld een tabel in een Excel-bestand of Google Spreadsheets zijn. De volgende gegevens zijn nodig om de registratie van meerdere producten tegelijkertijd mogelijk te maken: serienummer, postcode en huisnummer. U gaat als volgt te werk: '<ol><li>'Open het bronbestand waarin  de productregistraties staan. Dit kan een Excel-bestand zijn, of andersoortig bestand waarin gegevens in tabelvorm zijn opgeslagen.'</li><li>'Selecteer in het Excel-bestand de kolommen waarin de serienummers, postcodes en huisnummers staan. Let op: Kopieer alleen deze drie kolommen. Postcode en huisnummer zijn twee losse kolommen en de straatnaam kan achterwege gelaten worden. Laat ook andere kolommen achterwege.'</li><li>'Plak de gekopieerde kolommen in het gele invoerveld. Let op: Pas de gegevens op deze pagina niet verder aan. De ingevoerde gegevens kunnen later in het proces nog gemakkelijk worden aangepast. Is dit niet mogelijk? Kies dan voor handmatig invoeren.'</li></ol>'",
        "title": "Hoe moet ik een Excel-bestand kopiëren en plakken?"
      },
      "placeholder": {
        "house_number": "huisnummer",
        "postal_code": "postcode",
        "serial_number": "serienummer"
      }
    },
    "bulk_input_table": {
      "button": {
        "add_row": "Rij toevoegen",
        "add_row_mobile": "Registratie toevoegen",
        "delete_row_mobile": "Registratie verwijderen",
        "primary": "Volgende"
      },
      "error": {
        "serial_number_invalid": "Dit is een ongeldig serienummer",
        "serial_number_registered": "Dit serienummer is al geregistreerd"
      },
      "remove_row_modal": {
        "button": {
          "primary": "Verwijderen",
          "secondary": "Annuleren"
        },
        "text": "De gegevens uit deze rij gaan verloren. Deze actie kan niet ongedaan worden gemaakt.",
        "title": "Weet u het zeker?"
      },
      "table": {
        "city": "Plaats",
        "dropdown_extra": "extra",
        "dropdown_extras": "extra's",
        "dropdown_select": "Selecteer",
        "extra": "Extra",
        "extras": "Extra's",
        "house_number": "Huisnr.",
        "postal_code": "Postcode",
        "registered_serial_tooltip": "Dit serienummer is al geregistreerd en kan niet opnieuw worden verwerkt.",
        "serial_number": "Serienummer",
        "street": "Straat",
        "type": "Type"
      }
    },
    "bulk_manual": {
      "header": {
        "text": "Voer hier de registratie per regel in. Na het invoeren van het serienummer wordt automatisch het producttype opgehaald. Na het invoeren van de postcode en het huisnummer wordt automatisch de straatnaam en plaats ingevoerd. Daarnaast is het mogelijk om eventuele extra’s te selecteren.",
        "title": "Handmatig invoeren"
      }
    },
    "bulk_prepare": {
      "button": {
        "primary": "Volgende"
      },
      "header": {
        "text": "Koppel de juiste data aan het bijbehorende veld. Er worden maximaal 5 rijen getoond.",
        "title": "Selecteer de juiste kolommen"
      },
      "table": {
        "house_number": "Huisnummer",
        "irrelevant": "Niet relevant",
        "placeholder": "Maak keuze",
        "postal_code": "Postcode",
        "serial_number": "Serienummer"
      }
    },
    "comfofan_silent": {
      "confirmed": {
        "confirmation": "U ontvangt spoedig een bevestiging per e-mail.",
        "link": "'<a class=\"base-button base-button--is-gray\" href=\"https://www.zehnder.be/nl/inspiratie/gezondheid\" target=\"_blank\" rel=\"noopener noreferrer\">'Lees alles over een gezond (t)huis!'</a>'",
        "text": "De registratie van de ComfoFan Silent is geslaagd. U ontvangt spoedig een mail ter bevestiging.",
        "text_additional": "Met dit product heeft u gekozen voor een gezond binnenklimaat. Bent u benieuwd wat de effecten zijn van ventilatie op een gezond (t)huis?",
        "title": "Bedankt voor uw registratie!"
      },
      "view": {
        "button": "Start registratie",
        "text": "Nulla vitae elit libero, a pharetra augue. Maecenas sed diam eget risus varius blandit sit amet non magna.",
        "title": "Registreer nu de ComfoFan Silent"
      }
    },
    "comfofan_silent_find": {
      "button": "Start registratie",
      "modal": {
        "button": "Waar vind ik het serienummer?",
        "items": {
          "one": {
            "steps": {
              "one": {
                "title": "Verwijder de snelstart instructie van de deksel."
              },
              "two": {
                "title": "Lees het serienummer af van het typeplaatje."
              }
            },
            "title": "ComfoFan Silent"
          }
        },
        "text": "De plek van het serienummer verschilt per type product. Afhankelijk van welk product u wilt registreren, vindt u hieronder de stappen.",
        "title": "Waar vind ik het serienummer?"
      },
      "placeholder": "Bijvoorbeeld 903279002",
      "text": "Het serienummer is nodig om uw product te kunnen registeren. Kunt u deze niet vinden? Klik dan op 'waar vind ik het serienummer'.",
      "title": "Vul het serienummer in"
    },
    "create_account": {
      "error": {
        "button": "Vraag nieuwe link aan",
        "text": "Het account kon niet worden aangemaakt. Probeer het nog eens.",
        "title": "Er is iets misgegaan"
      },
      "idle": {
        "button": "Maak account aan",
        "consent": "Ik ga akkoord met de '<a href=\"https://zehnder.be/nl/service-kennis/service/montage-en-gebruik/tools-en-apps/product-registratie/registratievoorwaarden\" target=\"_blank\">'registratievoorwaarden'</a>'",
        "form": {
          "address": {
            "label": "Straatnaam*",
            "placeholder": "Vul de straatnaam in"
          },
          "companyName": {
            "label": "Bedrijfsnaam",
            "placeholder": "Vul de bedrijfsnaam in"
          },
          "email": {
            "label": "E-mail*",
            "placeholder": "Vul het e-mailadres in"
          },
          "house_number": {
            "label": "Huisnummer*",
            "placeholder": "Vul het huisnummer in"
          },
          "locality": {
            "label": "Plaatsnaam*",
            "placeholder": "Vul de plaatsnaam in"
          },
          "postal_code": {
            "label": "Postcode*",
            "placeholder": "Vul de postcode in"
          },
          "telephone": {
            "label": "Telefoon",
            "placeholder": "Vul uw telefoonnummer in"
          }
        },
        "text": "Vul hier uw zakelijke gegevens in om toegang te krijgen tot de registraties van uw installatiebedrijf.",
        "title": "Aanmaken account"
      },
      "running": {
        "title": "Bezig met laden..."
      },
      "success": {
        "text": "Via het opgegeven e-mailadres ontvangt u een link waarmee u kunt inloggen.",
        "title": "Uw account is aangemaakt"
      },
      "user_exists": "Er bestaat al een account met dit e-mailadres, probeer '<a class=\"text-red-400 bold\" href=\"/login\">'hier'</a>' in te loggen."
    },
    "find": {
      "button": "Ga verder",
      "faq_link": {
        "label": "Vragen? Bekijk de FAQ",
        "url": "https://www.zehnder.be/nl/service-kennis/service/montage-en-gebruik/tools-en-apps/product-registratie/veelgestelde-vragen"
      },
      "modal": {
        "button": "Waar vind ik het serienummer?",
        "items": {
          "one": {
            "steps": {
              "one": {
                "title": "Kijk naar de afdekkap van de display."
              },
              "two": {
                "title": "Lees het serienummer af van de sticker op de rechterkant van de afdekkap."
              }
            },
            "title": "ComfoAir Pro"
          },
          "three": {
            "steps": {
              "one": {
                "title": "Kijk naar de voorkant van de ComfoD."
              },
              "two": {
                "title": "Lees het serienummer af van de sticker op de bovenkant van het front."
              }
            },
            "title": "ComfoD"
          },
          "title": "Welk type wilt u registeren?",
          "two": {
            "steps": {
              "one": {
                "title": "Klap de zwarte klep van de ComfoAir Q open."
              },
              "three": {
                "title": "Lees het serienummer af van de sticker onder de netstroom aansluiting."
              },
              "two": {
                "title": "Verwijder de grijze slede die bovenop het apparaat zit door deze naar u toe te trekken."
              }
            },
            "title": "ComfoAir Q"
          }
        },
        "text": "De plek van het serienummer verschilt per type product. Afhankelijk van welk product u wilt registreren, vindt u hieronder de stappen.",
        "title": "Waar vind ik het serienummer?"
      },
      "not_found": "Sorry, het serienummer kan niet worden geregistreerd. Check hieronder waar u het serienummer kunt vinden, of '<a href=\"https://www.zehnder.be/nl/service-kennis/service/montage-en-gebruik/tools-en-apps/product-registratie/veelgestelde-vragen\" class=\"underline\" target=\"_blank\">'bekijk de veelgestelde vragen'</a>'.",
      "placeholder": "Bijvoorbeeld 903279002",
      "text": "En ontvang als installateur punten voor het Partner for Life programma of als bewoner extra ondersteuning op afstand.",
      "title": "Vul hier het serienummer in"
    },
    "index": {
      "button": {
        "create_account": "Account aanmaken",
        "dashboard": "Ga naar dashboard",
        "login": "Inloggen voor installateurs",
        "register": "Registreer product"
      },
      "text": "en profiteer van de voordelen. Als installateur en consument geniet u van de zekerheid van een goed werkend ventilatieproduct.",
      "title": "Registreer nu uw Zehnder ventilatieproduct"
    },
    "login": {
      "error": {
        "button": "Vraag nieuwe link aan",
        "text": "Klik hieronder op \"Vraag nieuwe link aan\" om een nieuwe link op te vragen.",
        "title": "Er is iets misgegaan"
      },
      "idle": {
        "button": "Inloggen",
        "form": {
          "email": {
            "label": "E-mailadres",
            "placeholder": "Vul uw e-mailadres in"
          }
        },
        "text": "Vul uw e-mailadres in om in te loggen en uw registraties te beheren.",
        "title": "Inloggen"
      },
      "not_found": {
        "button": "Ga terug",
        "text": "Het e-mailadres {email} kan niet worden gevonden.",
        "title": "E-mailadres niet gevonden"
      },
      "running": {
        "title": "Bezig met laden..."
      },
      "success": {
        "text": "U ontvangt een e-mail op {email} met daarin een link om in te loggen.",
        "title": "Controleer uw e-mail"
      }
    },
    "login_installer": "Inloggen",
    "maintenance_mode": {
      "system_not_available": "Vanwege onderhoudswerkzaamheden is de applicatie beperkt of niet beschikbaar tot nader order. Excuses voor het ongemak."
    },
    "nav_bar": {
      "home": "Thuis",
      "product_registrations": "Dashboard",
      "users": "Consumenten"
    },
    "pincode": {
      "error": "Dit lijkt geen geldig serienummer te zijn"
    },
    "product-registration-details": {
      "button": {
        "modify": "Aanpassen"
      },
      "label": {
        "address": "Adres",
        "date": "Registratiedatum",
        "description": "Omschrijving",
        "postal_code_locality": "Postcode en plaats",
        "serial_number": "Serienummer"
      },
      "locality_unknown": "Plaats onbekend",
      "model_unknown": "Model onbekend",
      "postal_code_unknown": "Postcode onbekend",
      "unknown": "Onbekend",
      "value_status": {
        "finalized": "Afgerond",
        "syncing": "Wordt verwerkt",
        "unfinalized": "Niet afgerond"
      }
    },
    "product-registration-update": {
      "button": {
        "save": "Registratie opslaan"
      },
      "installation_address": {
        "placeholder": "Straatnaam + huisnummer"
      },
      "installation_locality": {
        "placeholder": "Bijvoorbeeld Mechelen"
      },
      "installation_postal_code": {
        "placeholder": "Bijvoorbeeld 2800"
      },
      "installation_remarks": {
        "placeholder": "Bijvoorbeeld het nummer van de werf"
      },
      "label": {
        "address": "Adres",
        "date": "Registratiedatum",
        "description": "Omschrijving",
        "locality": "Plaats",
        "postal_code": "Postcode",
        "serial_number": "Serienummer",
        "type": "Type"
      }
    },
    "product_data": {
      "accessoires": {
        "combi_flow": "CombiFlow",
        "comfo_connect": "ComfoConnect (Gateway)",
        "comfo_cool": "ComfoCool/ComfoClime (Comfortunit)",
        "comfo_fond_l": "ComfoFond-L Q (Aardwarmtewisselaar)",
        "comfo_fresh": "ComfoFresh (Luchtverdeelsysteem)",
        "comfo_post": "ComfoPost",
        "overflow_technology": "Overflow Technology"
      }
    },
    "product_registrations": {
      "confirmed": {
        "consumer": {
          "confirmation": "U ontvangt spoedig een bevestiging per e-mail.",
          "link": "'<a class=\"base-button base-button--is-gray\" href=\"https://www.zehnder.be/nl/inspiratie/gezondheid\" target=\"_blank\" rel=\"noopener noreferrer\">'Lees alles over een gezond (t)huis!'</a>'",
          "text": "Uw registratie van de {productTitle} is geslaagd. U ontvangt spoedig een mail ter bevestiging en met extra informatie.",
          "text_additional": "Met dit product heeft u gekozen voor een gezond binnenklimaat. Bent u benieuwd wat de effecten zijn van ventilatie op een gezond (t)huis?",
          "title": "Bedankt voor uw registratie!"
        },
        "installer": {
          "button": "Beheer uw registratie(s)",
          "confirmation": "U ontvangt spoedig een bevestiging per e-mail.",
          "link_text": "Lees meer info over dit product",
          "postfix_complete_registration": "U ontvangt punten voor het Partner for Life programma.",
          "postfix_incomplete_registration": "Vul het adres aan om de registratie compleet te maken.",
          "text": "Bedankt voor het registreren van de {productTitle}.",
          "title": "Bedankt voor uw registratie!",
          "warranty": "Uw garantie loopt tot:",
          "warranty_not_found": "Er is geen garantiedatum bekend. Neem contact op."
        }
      },
      "view": {
        "back_to_link": "Terug naar overzicht"
      }
    },
    "product_registrations_create": {
      "accessoires": {
        "meta": "Zehnder",
        "text": "Geef hieronder aan of het systeem met extra's is uitgerust. Is dit niet het geval, of weet je het niet zeker? Klik dan op volgende.",
        "title": "Welke extra's zijn op het systeem aangesloten?"
      },
      "end_user_company_name": {
        "option_manual": "Door een bedrijf",
        "option_self_installed": "Door mijzelf",
        "option_unknown": "Weet ik niet",
        "placeholder": "Bijvoorbeeld Zehnder Installaties",
        "text": "Voer de bedrijfsnaam en plaatsnaam in van het bedrijf dat jouw Zehnder-apparaat geïnstalleerd heeft, indien deze bij jou bekend zijn.",
        "title": "Door wie is je product geïnstalleerd?"
      },
      "end_user_email": {
        "consent": "Ik ga akkoord met de '<a href=\"https://zehnder.be/nl/service-kennis/service/montage-en-gebruik/tools-en-apps/product-registratie/registratievoorwaarden\" target=\"_blank\">'registratievoorwaarden'</a>'",
        "placeholder": "Philip.lambert@zehnder.be",
        "text": "Geef hier een zakelijk e-mailadres op, indien van toepassing.",
        "title": "Wat is uw e-mailadres?"
      },
      "end_user_first_name": {
        "placeholder": "Philip"
      },
      "end_user_gender": {
        "gender": {
          "female": "Mevrouw",
          "male": "De heer"
        },
        "text": "",
        "title": "Hoe mogen we u aanspreken?"
      },
      "end_user_installation_address": {
        "placeholder": "Straatnaam + huisnummer",
        "text": "Vul hier de gegevens in van het adres waar het systeem is geïnstalleerd.",
        "title": "Op welk adres is het systeem geïnstalleerd?"
      },
      "end_user_last_name": {
        "placeholder": "Lambert"
      },
      "end_user_name": {
        "text": "Wij gebruiken uw naam voor de communicatie rond uw ventilatiesysteem.",
        "title": "Wat is uw naam?"
      },
      "end_user_reward_date": {
        "has_registered_consumer": "Dit product is al eerder geregistreerd en er zijn daarom al Zehnder Original Filters voor dit product uitgegeven. Het is niet mogelijk om een tweede gratis set filters te ontvangen. U kunt doorgaan naar de volgende stap om het registratieproces te vervolledigen of naar de '<a class=\"text-red-400 bold\" target=\"_blank\" href=\"https://be.zehnder-filtershop.com/nl\">'webshop'</a>' om Zehnder Original Filters te kopen.",
        "option": {
          "asap": "Binnen 3 dagen",
          "half_year": "Over 3 maanden",
          "month": "Over 1 maand",
          "week": "Over 1 week"
        },
        "text": "De filters worden verstuurd naar '<span class=\"text-gray-700 bold\">'{address}.'</span>'",
        "title": "Wanneer wilt u de gratis set filters ontvangen?"
      },
      "error": {
        "button": "Probeer opnieuw",
        "text": "De productregistratie kon niet worden opgeslagen door een technische fout.",
        "title": "Er gaat iets mis met het opslaan"
      },
      "installation_address": {
        "empty": "Geen resultaten gevonden",
        "loading": "Straten aan het laden...",
        "placeholder": "Straatnaam",
        "search_street": "Zoek een straat",
        "text": "Vul hier de gegevens in van het adres waar het systeem is geïnstalleerd. Weet u het adres niet, of is deze nog niet bekend? Klik dan op de onderstaande knop.",
        "title": "Op welk adres is het systeem geïnstalleerd?"
      },
      "installation_company_name": {
        "placeholder": "Bijvoorbeeld Zehnder Installaties",
        "text": "Vul hier de bedrijfsnaam en plaatsnaam in van het bedrijf waar u voor werkt.",
        "title": "Bij welk installatiebedrijf werkt u?"
      },
      "installation_house_number": {
        "placeholder": "Huisnr."
      },
      "installation_locality": {
        "placeholder": "Plaats",
        "text": "",
        "title": "In welke plaats is het systeem geïnstalleerd?"
      },
      "installation_postal_code": {
        "placeholder": "Postcode",
        "text": "",
        "title": "Wat is de postcode?"
      },
      "installation_remarks": {
        "placeholder": "bijvoorbeeld het huisnummer",
        "text": "Let op! De registratie is compleet wanneer het definitieve adres is opgegeven. Door een eigen referentie in te voeren, wordt er nog geen beloning gegeven. Zodra het adres bekend is, kunt u dit aan ons doorgeven en wordt de beloning toegekend.",
        "title": "Geef een omschrijving van de locatie"
      },
      "installation_search_again": "Zoek opnieuw",
      "installer_company_city": {
        "placeholder": "Bijvoorbeeld Mechelen",
        "text": "Vul hier de locatie in van waaruit u werkzaam bent voor dit bedrijf.",
        "title": "In welke plaats is het installatiebedrijf gevestigd?"
      },
      "installer_company_email": {
        "placeholder": "philip.lambert@zehnder.be",
        "text": "Geef hier een zakelijk e-mailadres op, indien van toepassing.",
        "title": "Wat is uw e-mailadres?"
      },
      "installer_completed": {
        "placeholder": "Zehnder Installateurs",
        "text": "",
        "title": "Uw registratie van de {productTitle} is geslaagd. U ontvangt spoedig een mail ter bevestiging en met extra informatie."
      },
      "installer_privacy": {
        "consent": "Ik ga akkoord met de '<a href=\"https://zehnder.be/nl/service-kennis/service/montage-en-gebruik/tools-en-apps/product-registratie/registratievoorwaarden\" target=\"_blank\">'registratievoorwaarden'</a>'",
        "text": "Door akkoord te gaan met de voorwaarden wordt de registratie ingediend.",
        "title": "U bent bijna klaar!"
      },
      "navigation_button": {
        "next": "Volgende",
        "previous": "Vorige",
        "skip": "Ik weet het adres niet"
      },
      "role": {
        "consumer": "Bewoner",
        "has_registered_installer": "Dit serienummer is al door een installateur geregistreerd, waardoor een nieuwe registratie op dit serienummer niet mogelijk is. Check of het om het juiste serienummer gaat, of '<a href=\"/login\" class=\"bold\">'raadpleeg het dashboard'</a>' om te zien of u dit product al geregistreerd hebt.",
        "installer": "Installateur",
        "text": "Vul hieronder in of u de bewoner of de installateur bent.",
        "title": "Ik registreer als"
      },
      "subscribe_newsletter": {
        "consent": "Ik wil mij aanmelden voor de Zehnder nieuwsbrief"
      }
    },
    "product_select": {
      "button_next": "Volgende",
      "button_prev": "Vorige",
      "not_selectable": "Dit product kan niet als installateur geregistreerd worden.",
      "products": {
        "one": {
          "text": "Balansventilatie (ventilatiesysteem D).",
          "title": "ComfoAir Q, Pro, Flex of ComfoD"
        },
        "two": {
          "text": "Mechanische afvoerventilatie (ventilatiesysteem C).",
          "title": "ComfoFan Silent"
        }
      },
      "text": "Kies uit een product van het type balansventilatie of mechanische ventilatie.",
      "title": "Welk type product wilt u registreren?",
      "wtw_not_available": "Op dit moment is het wegens technische problemen niet mogelijk om balansventilatieproducten te registeren. Probeer het op een later moment weer."
    },
    "products": {
      "view": {
        "call_to_action": {
          "button": "Bestel nu nieuwe luchtfilters",
          "description": "Originele luchtfilters van Zehnder verminderen het geluid van de ventilatie-unit, hebben een langere levensduur en werken efficiënter.",
          "title": "Profiteer met originele luchtfilters van Zehnder van een gezond binnenklimaat."
        },
        "has_registration": {
          "button": "Registreer opnieuw",
          "end_user_mail": "Geregistreerd door:",
          "floating_link": "'<a target=''_blank'' href='{link}'>Gedetailleerde productinformatie</a>'",
          "label": "Was u dit niet?",
          "next": "Registreer een nieuwe bewoner",
          "text": "Meer productinformatie op '<a target=''_blank'' href='{link}'>zehnder.be</a>'.",
          "title": "Deze {productTitle} is al geregistreerd",
          "warranty_end": "Geregistreerd tot:",
          "warranty_start": "Geregistreerd op:"
        },
        "has_registration_installer": {
          "button": "Ga naar dashboard",
          "text": "Dit serienummer is al door een installateur geregistreerd, waardoor een nieuwe registratie op dit serienummer niet mogelijk is. Check of het om het juiste serienummer gaat, of '<a href=\"/login\">'raadpleeg het dashboard'</a>' om te zien of u dit product al geregistreerd hebt."
        },
        "no_registration": {
          "button": "Start registratie",
          "text": "En spaar als installateur punten voor het Partner for Life programma of als bewoner voor gemakkelijke ondersteuning op afstand.",
          "title": "Registreer nu de {productTitle}"
        },
        "no_registration_installer": {
          "button": "Registreer als installateur"
        },
        "not_found": {
          "button": "Naar beginscherm",
          "text": "Er is geen product gevonden voor het ingevoerde serienummer. Probeer het over 24 uur nogmaals, of ga naar het beginscherm.",
          "title": "Product niet gevonden"
        }
      }
    },
    "products_verify": {
      "text": "Dit zijn de laatste 4 cijfers van het serienummer",
      "title": "Wat is het PIN-nummer op de QR-Code sticker?"
    },
    "profile": {
      "address": "Straat en huisnummer",
      "back_to_link": "Terug naar overzicht",
      "button": "Profiel aanpassen",
      "company_name": "Bedrijfsnaam",
      "email": "E-mail",
      "first_name": "Voornaam",
      "last_name": "Achternaam",
      "locality": "Plaats",
      "message_error": "Probleem met opslaan. Uw profiel kon niet worden bijgewerkt.",
      "message_success": "Uw profiel is bijgewerkt!",
      "phone": "Telefoon",
      "postal_code": "Postcode",
      "title": "Mijn profiel",
      "unknown": "Onbekend"
    },
    "region_switch": {
      "approve": "Bevestig uw keuze",
      "cancel": "Annuleren",
      "country_select": "Kies land",
      "language_select": "Kies taal",
      "text": "",
      "text_language": "",
      "title": "Kies uw land en taal",
      "title_language": "Kies uw taal"
    },
    "registrations_list": {
      "header": {
        "date": "Registratiedatum",
        "location": "Locatie",
        "postal_code": "Postcode",
        "serial_number": "Serienummer",
        "status": "Registratiestatus",
        "type": "Type"
      },
      "no_registrations": "Geen productregistraties gevonden.",
      "no_registrations_filtered": "Geen productregistraties gevonden met deze zoekcriteria.",
      "type_unknown": "Onbekend",
      "value_status": {
        "finalized": "Afgerond",
        "syncing": "Wordt verwerkt",
        "unfinalized": "Niet afgerond"
      }
    },
    "registrations_panel": {
      "button": {
        "filter": "Filter",
        "map": "Kaart",
        "new_registration": "Nieuwe registratie"
      },
      "filters": {
        "button": {
          "clear": "Verwijder filters"
        },
        "date": {
          "label": "Registratiedatum"
        },
        "search": {
          "label": "Zoek",
          "placeholder": "Adres of serienummer"
        },
        "type": {
          "count_suffix": "geselecteerd",
          "label": "Type"
        }
      },
      "tab_all": "Alles",
      "tab_finalized": "Afgerond",
      "tab_unfinalized": {
        "default": "Niet afgerond",
        "mobile": "Onafgerond"
      }
    },
    "support_links": {
      "filter_shop": "Filters en accessoires bestellen",
      "product_info": "Productinformatie",
      "service": "Service en ondersteuning",
      "title": "Onderhoud en service"
    },
    "toaster": {
      "address_not_available": "Geen adres kan worden gevonden voor deze combinatie van postcode en huisnummer.",
      "addresses_not_available": "De adressen konden niet automatisch worden opgehaald. Klik hier om het opnieuw te proberen, of neem contact met ons op als het probleem aanhoudt.",
      "create_registration_failed": "De registratie is mislukt. Klik hier om het opnieuw te proberen, of neem contact met ons op als het probleem aanhoudt.",
      "product_not_available": "Geen product kan worden gevonden met dit serienummer.",
      "products_not_available": "De producten konden niet automatisch worden opgehaald. Klik hier om het opnieuw te proberen, of neem contact met ons op als het probleem aanhoudt."
    },
    "user_navigation": {
      "dashboard": "Dashboard",
      "link": "Ga naar zehnder.be",
      "link_title": "Zehnder.be",
      "link_url": "https://www.zehnder.be/nl/",
      "logout": "Log uit",
      "my_profile": "Mijn profiel",
      "product_registrations": "Productregistraties",
      "switch_language": "Passer au Français"
    },
    "validations": {
      "account": {
        "address_empty": "Straatnaam kan niet leeg zijn",
        "email_empty": "E-mail kan niet leeg zijn",
        "email_invalid": "Het ingevoerde e-mailadres lijkt ongeldig",
        "house_number_empty": "Huisnummer kan niet leeg zijn",
        "locality_empty": "Plaats kan niet leeg zijn",
        "postal_code_empty": "Postcode kan niet leeg zijn",
        "postal_code_invalid": "Geef een geldige Belgische postcode op, bijvoorbeeld \"2800\""
      },
      "bulk": {
        "city_empty": "Vul hier de plaats in",
        "general_error": "Ongeldige invoer, controleer de rood gemarkeerde velden",
        "house_number_empty": "Vul hier het huisnummer in",
        "postal_code_empty": "Vul hier de postcode in",
        "postal_code_invalid": "Geef een geldige Belgische postcode op, bijvoorbeeld \"2800\"",
        "serial_number_empty": "Vul hier het serienummer om door te gaan",
        "serial_number_invalid": "Dit is een ongeldig serienummer",
        "serial_number_registered": "Dit serienummer is al geregistreerd",
        "serial_number_unknown": "Er kan geen product gevonden worden met dit serienummer",
        "street_empty": "Vul hier de straat in"
      },
      "comfofan_silent": {
        "batch_number_empty": "Geef een serienummer op.",
        "batch_number_length": "Foutief serienummer. Het serienummer bestaat uit 8 - 12 cijfers.",
        "batch_number_type": "Een serienummer bestaat alleen uit cijfers."
      },
      "login": {
        "email_empty": "E-mail kan niet leeg zijn",
        "email_invalid": "Het ingevoerde e-mailadres lijkt ongeldig"
      },
      "product": {
        "serial_number_empty": "Serienummer kan niet leeg zijn"
      },
      "product_registration": {
        "email_empty": "E-mail kan niet leeg zijn",
        "email_invalid": "Het ingevoerde e-mailadres lijkt ongeldig",
        "postal_code_empty": "Postcode kan niet leeg zijn",
        "postal_code_invalid": "Geef een geldige Belgische postcode op, bijvoorbeeld \"2800\""
      },
      "product_registrations_update": {
        "address_empty": "Adres kan niet leeg zijn",
        "description_empty": "Beschrijving kan niet leeg zijn",
        "locality_empty": "Plaats kan niet leeg zijn",
        "postal_code_empty": "Postcode kan niet leeg zijn",
        "postal_code_invalid": "Geef een geldige Belgische postcode op, bijvoorbeeld \"2800\""
      },
      "profile": {
        "postal_code_invalid": "Geef een geldige Belgische postcode op, bijvoorbeeld \"2800\""
      }
    },
    "version": {
      "back_link_text": "Terug naar beginscherm",
      "title": "App-info",
      "version_prefix": "Versie"
    }
  }], ["nl-nl", {
    "app_title": "Zehnder - Productregistratie",
    "bulk": {
      "combi_flow": "CombiFlow",
      "comfo_connect": "ComfoConnect (Gateway)",
      "comfo_cool": "ComfoCool/ComfoClime (Comfortunit)",
      "comfo_fond_l": "ComfoFond-L Q (Aardwarmtewisselaar)",
      "comfo_fresh": "ComfoFresh (Luchtverdeelsysteem)",
      "comfo_post": "ComfoPost",
      "leave_page_warning": "Weet je zeker dat je de pagina wil verlaten? Je ingevoerde gegevens worden niet opgeslagen.",
      "overflow_technology": "Overflow Technology"
    },
    "bulk_amend": {
      "header": {
        "text": "Controleer of onderstaande gegevens kloppen en wijzig deze waar nodig.",
        "title": "Controleer en corrigeer de gegevens"
      }
    },
    "bulk_confirmed": {
      "button": {
        "primary": "Terug naar dashboard"
      },
      "header": {
        "text_plural": "Gefeliciteerd! De registraties van je producten zijn gelukt. Je ontvangt een mail ter bevestiging. De registraties zijn nu ook te zien in het dashboard.",
        "text_singular": "Gefeliciteerd! De registratie van je product is gelukt. Je ontvangt een mail ter bevestiging. De registratie is nu ook te zien in het dashboard.",
        "title_plural": "{count} nieuwe registraties toegevoegd",
        "title_singular": "{count} nieuwe registratie toegevoegd"
      }
    },
    "bulk_enter": {
      "button": {
        "help": "Hulp nodig?",
        "primary": "Volgende",
        "secondary": "Handmatig invoeren"
      },
      "header": {
        "text": "Plak de velden '<b>'serienummer, postcode en huisnummer (incl. toevoeging)'</b>' vanuit een excel of andere database hierin. Je kunt ook de data '<a class=\"text-red-400 bold\" href=\"/product-registrations/bulk/manual\">'handmatig invoeren'</a>'.",
        "title": "Kopiëren en plakken"
      },
      "modal": {
        "text": "Via deze pagina registreer je meerdere producten tegelijk. Als basis hiervoor kun je een bestaande tabel gebruiken waarin je de gegevens van de registraties hebt opgeslagen. Dit kan bijvoorbeeld een tabel in een Excel-bestand of Google Spreadsheets zijn. Zodat je met de registratie van meerdere producten tegelijkertijd kunt starten, hebben wij de volgende gegevens nodig om het registratieproces zo makkelijk mogelijk te maken: serienummer, postcode en huisnummer. Je gaat als volgt te werk: '<ol><li>'Open het bronbestand waarin je zelf de productregistraties hebt bijgehouden. Dit kan een Excel-bestand zijn, of andersoortig bestand waarin je gegevens in tabelvorm zijn opgeslagen.'</li><li>'Selecteer in je Excel-bestand de kolommen waarin de serienummers, postcodes en huisnummers staan. Let op: Kopieer alleen deze drie kolommen. Postcode en huisnummer zijn twee losse kolommen en de straatnaam kan achterwege gelaten worden. Ook andere kolommen laat je achterwege.'</li><li>'Plak de gekopieerde kolommen in het gele invoerveld. Let op: Pas de gegevens op deze pagina niet verder aan. De ingevoerde gegevens kun je later in het proces nog gemakkelijk aanpassen. Heb je geen Excel-bestand om vanuit te kopiëren? Kies dan voor handmatig invoeren.'</li></ol>'",
        "title": "Hoe moet je een Excel-bestand kopiëren en plakken?"
      },
      "placeholder": {
        "house_number": "huisnummer",
        "postal_code": "postcode",
        "serial_number": "serienummer"
      }
    },
    "bulk_input_table": {
      "button": {
        "add_row": "Rij toevoegen",
        "add_row_mobile": "Registratie toevoegen",
        "delete_row_mobile": "Registratie verwijderen",
        "primary": "Volgende"
      },
      "error": {
        "serial_number_invalid": "Dit is een ongeldig serienummer",
        "serial_number_registered": "Dit serienummer is al geregistreerd"
      },
      "remove_row_modal": {
        "button": {
          "primary": "Verwijderen",
          "secondary": "Annuleren"
        },
        "text": "De gegevens die je in hebt gevoerd in deze rij gaan verloren. Deze actie kan niet ongedaan worden gemaakt.",
        "title": "Weet je het zeker?"
      },
      "table": {
        "city": "Plaats",
        "dropdown_extra": "extra",
        "dropdown_extras": "extra's",
        "dropdown_select": "Selecteer",
        "extra": "Extra",
        "extras": "Extra's",
        "house_number": "Huisnr.",
        "postal_code": "Postcode",
        "registered_serial_tooltip": "Dit serienummer is al geregistreerd en kan niet opnieuw worden verwerkt.",
        "serial_number": "Serienummer",
        "street": "Straat",
        "type": "Type"
      }
    },
    "bulk_manual": {
      "header": {
        "text": "Voer hier de registratie per regel in. Na het invoeren van het serienummer wordt automatisch het producttype opgehaald. Na het invoeren van de postcode en het huisnummer wordt automatisch de straatnaam en plaats ingevoerd. Daarnaast is het mogelijk om eventuele extra’s te selecteren.",
        "title": "Handmatig invoeren"
      }
    },
    "bulk_prepare": {
      "button": {
        "primary": "Volgende"
      },
      "header": {
        "text": "Koppel de juiste data aan het bijbehorende veld. Er worden maximaal 5 rijen getoond.",
        "title": "Selecteer de juiste kolommen"
      },
      "table": {
        "house_number": "Huisnummer",
        "irrelevant": "Niet relevant",
        "placeholder": "Maak keuze",
        "postal_code": "Postcode",
        "serial_number": "Serienummer"
      }
    },
    "comfofan_silent": {
      "confirmed": {
        "confirmation": "Je ontvangt spoedig een bevestiging per e-mail.",
        "link": "'<a class=\"base-button base-button--is-gray\" href=\"https://www.zehnder.nl/gezondheid\" target=\"_blank\" rel=\"noopener noreferrer\">'Lees alles over een gezond (t)huis!'</a>'",
        "text": "Je registratie van de ComfoFan Silent is geslaagd. Je ontvangt spoedig een mail ter bevestiging.",
        "text_additional": "Met dit product heb je gekozen voor een gezond binnenklimaat. Ben je benieuwd wat de effecten zijn van ventilatie op een gezond (t)huis?",
        "title": "Bedankt voor je registratie!"
      },
      "view": {
        "button": "Start registratie",
        "text": "Nulla vitae elit libero, a pharetra augue. Maecenas sed diam eget risus varius blandit sit amet non magna.",
        "title": "Registreer nu de ComfoFan Silent"
      }
    },
    "comfofan_silent_find": {
      "button": "Start registratie",
      "modal": {
        "button": "Waar vind ik het serienummer?",
        "items": {
          "one": {
            "steps": {
              "one": {
                "title": "Verwijder de snelstart instructie van de deksel."
              },
              "two": {
                "title": "Lees het serienummer af van het typeplaatje."
              }
            },
            "title": "ComfoFan Silent"
          }
        },
        "text": "De plek van het serienummer verschilt per type product. Afhankelijk van welk product je wilt registreren, vind je hieronder de stappen.",
        "title": "Waar vind ik het serienummer?"
      },
      "placeholder": "Bijvoorbeeld 903279002",
      "text": "Het serienummer is nodig om je product te kunnen registeren. Kun je deze niet vinden? Klik dan op 'waar vind ik het serienummer'.",
      "title": "Vul het serienummer in"
    },
    "create_account": {
      "error": {
        "button": "Vraag nieuwe link aan",
        "text": "Het account kon niet worden aangemaakt. Probeer het nog eens.",
        "title": "Er is iets misgegaan"
      },
      "idle": {
        "button": "Maak account aan",
        "consent": "Ik ga akkoord met de '<a href=\"https://www.zehnder.nl/zehnderzeker/registratievoorwaarden\" target=\"_blank\">'registratie voorwaarden'</a>'",
        "form": {
          "address": {
            "label": "Straatnaam*",
            "placeholder": "Vul je straatnaam in"
          },
          "companyName": {
            "label": "Bedrijfsnaam",
            "placeholder": "Vul de bedrijfsnaam in"
          },
          "email": {
            "label": "E-mail*",
            "placeholder": "Vul je e-mail in"
          },
          "house_number": {
            "label": "Huisnummer*",
            "placeholder": "Vul je huisnummer in"
          },
          "locality": {
            "label": "Plaatsnaam*",
            "placeholder": "Vul je plaatsnaam in"
          },
          "postal_code": {
            "label": "Postcode*",
            "placeholder": "Vul je postcode in"
          },
          "telephone": {
            "label": "Telefoon",
            "placeholder": "Vul je telefoonnummer in"
          }
        },
        "text": "Vul hier je zakelijke gegevens in om toegang te krijgen tot de registraties van jouw installatiebedrijf.",
        "title": "Aanmaken account"
      },
      "running": {
        "title": "Bezig met laden..."
      },
      "success": {
        "text": "Via het opgegeven e-mailadres ontvang je een link waarmee je kunt inloggen.",
        "title": "Je account is aangemaakt"
      },
      "user_exists": "Er bestaat al een account met dit e-mailadres, probeer '<a class=\"text-red-400 bold\" href=\"/login\">'hier'</a>' in te loggen."
    },
    "find": {
      "button": "Ga verder",
      "faq_link": {
        "label": "Vragen? Bekijk de FAQ",
        "url": "https://www.zehnder.nl/zehnder-zeker-veelgestelde-vragen"
      },
      "modal": {
        "button": "Waar vind ik het serienummer?",
        "items": {
          "one": {
            "steps": {
              "one": {
                "title": "Kijk naar de afdekkap van de display."
              },
              "two": {
                "title": "Lees het serienummer af van de sticker op de rechterkant van de afdekkap."
              }
            },
            "title": "ComfoAir E"
          },
          "three": {
            "steps": {
              "one": {
                "title": "Kijk naar de voorkant van de ComfoAir Standard."
              },
              "two": {
                "title": "Lees het serienummer af van de sticker op de bovenkant van het front."
              }
            },
            "title": "ComfoAir Standard"
          },
          "title": "Welk type wilt u registeren?",
          "two": {
            "steps": {
              "one": {
                "title": "Klap de zwarte klep van de ComfoAir Q open."
              },
              "three": {
                "title": "Lees het serienummer af van de sticker onder de netstroom aansluiting."
              },
              "two": {
                "title": "Verwijder de grijze slede die bovenop het apparaat zit door deze naar u toe te trekken."
              }
            },
            "title": "ComfoAir Q"
          }
        },
        "text": "De plek van het serienummer verschilt per type product. Afhankelijk van welk product je wilt registreren, vind je hieronder de stappen.",
        "title": "Waar vind ik het serienummer?"
      },
      "not_found": "Sorry, het serienummer kan niet worden geregistreerd. Check hieronder waar je het serienummer kunt vinden, of '<a href=\"https://www.zehnder.nl/zehnder-zeker-veelgestelde-vragen\" class=\"underline\" target=\"_blank\">'bekijk de veelgestelde vragen'</a>'.",
      "placeholder": "Bijvoorbeeld 903279002",
      "text": "En spaar als installateur punten voor het Partner for Life programma of ontvang als bewoner 50% korting op een Zehnder Original filterset in onze webshop.",
      "title": "Vul hier het serienummer in"
    },
    "index": {
      "button": {
        "create_account": "Account aanmaken",
        "dashboard": "Ga naar dashboard",
        "login": "Inloggen voor installateurs",
        "register": "Registreer product"
      },
      "text": "en profiteer van de voordelen. Als installateur en consument geniet je van de zekerheid van een goed werkend ventilatie product.",
      "title": "Registreer nu je Zehnder ventilatie product"
    },
    "login": {
      "error": {
        "button": "Vraag nieuwe link aan",
        "text": "Klik hieronder op \"Vraag nieuwe link aan\" opvragen om een nieuwe link op te vragen.",
        "title": "Er is iets misgegaan"
      },
      "idle": {
        "button": "Inloggen",
        "form": {
          "email": {
            "label": "E-mailadres",
            "placeholder": "Vul je e-mailadres in"
          }
        },
        "text": "Vul je email adres in om in te loggen en je registraties te beheren.",
        "title": "Inloggen"
      },
      "not_found": {
        "button": "Ga terug",
        "text": "Het e-mailadres {email} kan niet worden gevonden.",
        "title": "E-mailadres niet gevonden"
      },
      "running": {
        "title": "Bezig met laden..."
      },
      "success": {
        "text": "Je ontvangt een e-mail op {email} met daarin een link om in te loggen.",
        "title": "Check je e-mail"
      }
    },
    "login_installer": "Inloggen",
    "maintenance_mode": {
      "system_not_available": "Vanwege onderhoudswerkzaamheden is de applicatie beperkt of niet beschikbaar tot nader order. Excuses voor het ongemak."
    },
    "nav_bar": {
      "home": "Thuis",
      "product_registrations": "Dashboard",
      "users": "Gebruikers"
    },
    "pincode": {
      "error": "Dit lijkt geen geldige code te zijn"
    },
    "product-registration-details": {
      "button": {
        "modify": "Aanpassen"
      },
      "label": {
        "address": "Adres",
        "date": "Registratiedatum",
        "description": "Omschrijving",
        "postal_code_locality": "Postcode en plaats",
        "serial_number": "Serienummer"
      },
      "locality_unknown": "plaats onbekend",
      "model_unknown": "Model onbekend",
      "postal_code_unknown": "Postcode onbekend",
      "unknown": "Onbekend",
      "value_status": {
        "finalized": "Afgerond",
        "syncing": "Wordt verwerkt",
        "unfinalized": "Niet afgerond"
      }
    },
    "product-registration-update": {
      "button": {
        "save": "Registratie opslaan"
      },
      "installation_address": {
        "placeholder": "Straatnaam + huisnummer"
      },
      "installation_locality": {
        "placeholder": "Bijvoorbeeld Zwolle"
      },
      "installation_postal_code": {
        "placeholder": "Bijvoorbeeld 8012 AB"
      },
      "installation_remarks": {
        "placeholder": "Bijvoorbeeld het kavelnummer"
      },
      "label": {
        "address": "Adres",
        "date": "Registratiedatum",
        "description": "Omschrijving",
        "locality": "Plaats",
        "postal_code": "Postcode",
        "serial_number": "Serienummer",
        "type": "Type"
      }
    },
    "product_data": {
      "accessoires": {
        "combi_flow": "CombiFlow",
        "comfo_connect": "ComfoConnect (Gateway)",
        "comfo_cool": "ComfoCool/ComfoClime (Comfortunit)",
        "comfo_fond_l": "ComfoFond-L Q (Aardwarmtewisselaar)",
        "comfo_fresh": "ComfoFresh (Luchtverdeelsysteem)",
        "comfo_post": "ComfoPost",
        "overflow_technology": "Overflow Technology"
      }
    },
    "product_registrations": {
      "confirmed": {
        "consumer": {
          "confirmation": "Je ontvangt spoedig een bevestiging per e-mail.",
          "link": "'<a class=\"base-button base-button--is-gray\" href=\"https://www.zehnder.nl/nl/inspiratie/over-ventilatie/tips-voor-een-goedwerkend-systeem\" target=\"_blank\" rel=\"noopener noreferrer\">'Lees meer'</a>'",
          "text": "Je registratie van de {productTitle} is geslaagd. Je ontvangt spoedig een mail ter bevestiging en met extra informatie.",
          "text_additional": "Wil jij ook verzekerd zijn van een optimaal werkend ventilatiesysteem? Ontdek hoe je jouw ventilatiesysteem in topconditie houdt en hoe Zehnder jou daarin kan ondersteunen.",
          "title": "Bedankt voor je registratie!"
        },
        "installer": {
          "button": "Beheer je registratie(s)",
          "confirmation": "Je ontvangt spoedig een bevestiging per e-mail.",
          "link_text": "Lees meer info over dit product",
          "postfix_complete_registration": "Je ontvangt extra garantie.",
          "postfix_incomplete_registration": "Vul het adres aan om de registratie compleet te maken.",
          "text": "Bedankt voor het registreren van de {productTitle}.",
          "title": "Bedankt voor je registratie!",
          "warranty": "Uw garantie loopt tot:",
          "warranty_not_found": "Er is geen garantiedatum bekend. Neem contact op."
        }
      },
      "view": {
        "back_to_link": "Terug naar overzicht"
      }
    },
    "product_registrations_create": {
      "accessoires": {
        "meta": "Zehnder",
        "text": "Geef hieronder aan of het systeem met extra's is uitgerust. Is dit niet het geval, of weet je het niet zeker? Klik dan op volgende.",
        "title": "Welke extra's zijn op het systeem aangesloten?"
      },
      "end_user_company_name": {
        "option_manual": "Door een bedrijf",
        "option_self_installed": "Door mijzelf",
        "option_unknown": "Weet ik niet",
        "placeholder": "Bijvoorbeeld Zehnder Installaties",
        "text": "Voer de bedrijfsnaam en plaatsnaam in van het bedrijf dat jouw Zehnder-apparaat geïnstalleerd heeft, indien deze bij jou bekend zijn.",
        "title": "Door wie is je product geïnstalleerd?"
      },
      "end_user_email": {
        "consent": "Ik ga akkoord met de '<a href=\"https://www.zehnder.nl/zehnderzeker/registratievoorwaarden\" target=\"_blank\">'registratie voorwaarden'</a>'",
        "placeholder": "jan.janssen@zehnder.nl",
        "text": "Geef hier een zakelijke e-mailadres op, indien van toepassing.",
        "title": "Wat is je e-mailadres?"
      },
      "end_user_first_name": {
        "placeholder": "Jan"
      },
      "end_user_gender": {
        "gender": {
          "female": "Mevrouw",
          "male": "De heer"
        },
        "text": "",
        "title": "Hoe mogen we je aanspreken?"
      },
      "end_user_installation_address": {
        "placeholder": "Straatnaam + huisnummer",
        "text": "Vul hier de gegevens in van het adres waar het systeem is geïnstalleerd.",
        "title": "Op welk adres is het systeem geïnstalleerd?"
      },
      "end_user_last_name": {
        "placeholder": "Janssen"
      },
      "end_user_name": {
        "text": "Wij gebruiken je naam voor de communicatie rondom je ventilatieproduct.",
        "title": "Wat is je naam?"
      },
      "end_user_reward_date": {
        "has_registered_consumer": "Dit product is al eerder geregistreerd en er zijn daarom al Zehnder Original Filters voor dit product uitgegeven. Het is niet mogelijk om een tweede gratis set filters te ontvangen. Je kunt doorgaan naar de volgende stap om het registratieproces te vervolgen of naar de '<a class=\"text-red-400 bold\" target=\"_blank\" href=\"https://nl.zehnder-filtershop.com/nl\">'webshop'</a>' om Zehnder Original Filters te kopen.",
        "option": {
          "asap": "Binnen 3 dagen",
          "half_year": "Over 3 maanden",
          "month": "Over 1 maand",
          "week": "Over 1 week"
        },
        "text": "De filters worden verstuurd naar '<span class=\"text-gray-700 bold\">'{address}.'</span>'",
        "title": "Wanneer wil je de gratis set filters ontvangen?"
      },
      "error": {
        "button": "Probeer opnieuw",
        "text": "De productregistratie kon niet worden opgeslagen door een technische fout.",
        "title": "Er gaat iets mis met het opslaan"
      },
      "installation_address": {
        "empty": "Geen resultaten gevonden",
        "loading": "Straten aan het laden...",
        "placeholder": "Straatnaam",
        "search_street": "Zoek een straat",
        "text": "Vul hier de gegevens in van het adres waar het systeem is geïnstalleerd. Weet je het adres niet, of is deze nog niet bekend? Klik dan op de onderstaande knop.",
        "title": "Op welk adres is het systeem geïnstalleerd?"
      },
      "installation_company_name": {
        "placeholder": "Bijvoorbeeld Zehnder Installaties",
        "text": "Vul hier de bedrijfsnaam en plaatsnaam in van het bedrijf waar je voor werkt.",
        "title": "Bij welk installatiebedrijf werk je?"
      },
      "installation_house_number": {
        "placeholder": "Huisnr."
      },
      "installation_locality": {
        "placeholder": "Plaats",
        "text": "",
        "title": "In welke plaats is het systeem geïnstalleerd?"
      },
      "installation_postal_code": {
        "placeholder": "Postcode",
        "text": "",
        "title": "Wat is de postcode?"
      },
      "installation_remarks": {
        "placeholder": "bijvoorbeeld het kavelnummer",
        "text": "Let op! De registratie is compleet wanneer het definitieve adres is opgegeven. Door een eigen referentie in te voeren, wordt er nog geen beloning gegeven. Zodra het adres bekend is, kun je dit aan ons doorgeven en wordt de beloning toegekend.",
        "title": "Geef een omschrijving van de locatie"
      },
      "installation_search_again": "Zoek opnieuw",
      "installer_company_city": {
        "placeholder": "Bijvoorbeeld Zwolle",
        "text": "Vul hier de locatie in van waar uit jij werkzaam bent voor dit bedrijf.",
        "title": "In welke plaats is het installatiebedrijf gevestigd?"
      },
      "installer_company_email": {
        "placeholder": "jan.janssen@zehnder.nl",
        "text": "Geef hier een zakelijke e-mailadres op, indien van toepassing.",
        "title": "Wat is uw e-mailadres?"
      },
      "installer_completed": {
        "placeholder": "Zehnder Installateurs BV",
        "text": "",
        "title": "Je registratie van de {productTitle} is geslaagd. Je ontvangt spoedig een mail ter bevestiging en met extra informatie."
      },
      "installer_privacy": {
        "consent": "Ik ga akkoord met de '<a href=\"https://www.zehnder.nl/zehnderzeker/registratievoorwaarden\" target=\"_blank\">'registratie voorwaarden'</a>'",
        "text": "Door akkoord te gaan met de voorwaarden wordt de registratie ingediend.",
        "title": "Je bent bijna klaar!"
      },
      "navigation_button": {
        "next": "Volgende",
        "previous": "Vorige",
        "skip": "Ik weet het adres niet"
      },
      "role": {
        "consumer": "Bewoner",
        "has_registered_installer": "Dit serienummer is al door een installateur geregistreerd, waardoor een nieuwe registratie op dit serienummer niet mogelijk is. Check of het om het juiste serienummer gaat, of '<a href=\"/login\" class=\"bold\">'raadpleeg het dashboard'</a>' om te zien of je dit product al geregistreerd hebt.",
        "installer": "Installateur",
        "text": "Vul hieronder in of jij de bewoner of de installateur bent.",
        "title": "Ik registreer als"
      },
      "subscribe_newsletter": {
        "consent": "Ik wil me aanmelden voor de Zehnder nieuwsbrief"
      }
    },
    "product_select": {
      "button_next": "Volgende",
      "button_prev": "Vorige",
      "not_selectable": "Dit product kan niet als installateur geregistreerd worden.",
      "products": {
        "one": {
          "text": "Balansventilatie met warmteterugwinning (WTW).",
          "title": "ComfoAir Q, E of Standard"
        },
        "two": {
          "text": "Mechanische afvoerventilatie (ventilatiesysteem C).",
          "title": "ComfoFan Silent"
        }
      },
      "text": "Kies uit een product van het type balansventilatie of mechanische ventilatie.",
      "title": "Welk type product wil je registreren?",
      "wtw_not_available": "Op dit moment is wegen technische problemen niet mogelijk om warmteterugwinning (WTW) producten te registeren. Probeer het op een later moment weer."
    },
    "products": {
      "view": {
        "call_to_action": {
          "button": "Bestel nu nieuwe luchtfilters",
          "description": "Originele luchtfilters van Zehnder verminderen het geluid van de ventilatie-unit, hebben een langere levensduur en werken efficiënter.",
          "title": "Profiteer met originele luchtfilters van Zehnder van een gezond binnenklimaat."
        },
        "has_registration": {
          "button": "Registreer opnieuw",
          "end_user_mail": "Geregistreerd door:",
          "floating_link": "'<a target=''_blank'' href='{link}'>Gedetailleerde productinformatie</a>'",
          "label": "Was u dit niet?",
          "next": "Registreer een nieuwe bewoner",
          "text": "Meer productinformatie op '<a target=''_blank'' href='{link}'>zehnder.nl</a>'.",
          "title": "Deze {productTitle} is al geregistreerd",
          "warranty_end": "Geregistreerd tot:",
          "warranty_start": "Geregistreerd op:"
        },
        "has_registration_installer": {
          "button": "Ga naar dashboard",
          "text": "Dit serienummer is al door een installateur geregistreerd, waardoor een nieuwe registratie op dit serienummer niet mogelijk is. Check of het om het juiste serienummer gaat, of '<a href=\"/login\">'raadpleeg het dashboard'</a>' om te zien of je dit product al geregistreerd hebt."
        },
        "no_registration": {
          "button": "Start registratie",
          "text": "En spaar als installateur punten voor het Partner for Life programma of ontvang als bewoner 50% korting op een Zehnder Original filterset in onze webshop.",
          "title": "Registreer nu de {productTitle}"
        },
        "no_registration_installer": {
          "button": "Registreer als installateur"
        },
        "not_found": {
          "button": "Naar beginscherm",
          "text": "Er is geen product gevonden voor het ingevoerde serienummer, probeer het met 24 uur nogmaals, of ga naar het beginscherm.",
          "title": "Product niet gevonden"
        }
      }
    },
    "products_verify": {
      "text": "Dit zijn de laatste 4 cijfers van het serienummer",
      "title": "Wat is het PIN nummer op de QR-Code sticker?"
    },
    "profile": {
      "address": "Straat en huisnummer",
      "back_to_link": "Terug naar overzicht",
      "button": "Profiel aanpassen",
      "company_name": "Bedrijfsnaam",
      "email": "E-mail",
      "first_name": "Voornaam",
      "last_name": "Achternaam",
      "locality": "Plaats",
      "message_error": "Probleem met opslaan. Je profiel kon niet worden bijgewerkt.",
      "message_success": "Je profiel is bijgewerkt!",
      "phone": "Telefoon",
      "postal_code": "Postcode",
      "title": "Mijn profiel",
      "unknown": "Onbekend"
    },
    "region_switch": {
      "approve": "Bevestig uw keuze",
      "cancel": "Annuleren",
      "country_select": "Kies land",
      "language_select": "Kies taal",
      "text": "",
      "text_language": "",
      "title": "Kies jouw land en taal",
      "title_language": "Kies jouw taal"
    },
    "registrations_list": {
      "header": {
        "date": "Registratiedatum",
        "location": "Locatie",
        "postal_code": "Postcode",
        "serial_number": "Serienummer",
        "status": "Registratiestatus",
        "type": "Type"
      },
      "no_registrations": "Geen productregistraties gevonden.",
      "no_registrations_filtered": "Geen productregistraties gevonden met deze zoekcriteria.",
      "type_unknown": "Onbekend",
      "value_status": {
        "finalized": "Afgerond",
        "syncing": "Wordt verwerkt",
        "unfinalized": "Niet afgerond"
      }
    },
    "registrations_panel": {
      "button": {
        "filter": "Filter",
        "map": "Kaart",
        "new_registration": "Nieuwe registratie"
      },
      "filters": {
        "button": {
          "clear": "Verwijder filters"
        },
        "date": {
          "label": "Registratiedatum"
        },
        "search": {
          "label": "Zoek",
          "placeholder": "Adres of serienummer"
        },
        "type": {
          "count_suffix": "geselecteerd",
          "label": "Type"
        }
      },
      "tab_all": "Alles",
      "tab_finalized": "Afgerond",
      "tab_unfinalized": {
        "default": "Niet afgerond",
        "mobile": "Onafgerond"
      }
    },
    "support_links": {
      "filter_shop": "Filters en accessoires bestellen",
      "product_info": "Productinformatie",
      "service": "Service en ondersteuning",
      "title": "Onderhoud en service"
    },
    "toaster": {
      "address_not_available": "Er kan geen adres worden gevonden voor deze combinatie van postcode en huisnummer.",
      "addresses_not_available": "De adressen konden niet automatisch worden opgehaald. Klik hier om het opnieuw te proberen, of neem contact met ons op als het probleem aanhoudt.",
      "create_registration_failed": "De registratie is mislukt. Klik hier om het opnieuw te proberen, of neem contact met ons op als het probleem aanhoudt.",
      "product_not_available": "Er kan geen product worden gevonden met dit serienummer.",
      "products_not_available": "De producten konden niet automatisch worden opgehaald. Klik hier om het opnieuw te proberen, of neem contact met ons op als het probleem aanhoudt."
    },
    "user_navigation": {
      "dashboard": "Dashboard",
      "link": "Ga naar zehnder.nl",
      "link_title": "Zehnder.nl",
      "link_url": "https://www.zehnder.nl",
      "logout": "Log uit",
      "my_profile": "Mijn profiel",
      "product_registrations": "Productregistraties",
      "switch_language": "Switch to English"
    },
    "validations": {
      "account": {
        "address_empty": "Straatnaam kan niet leeg zijn",
        "email_empty": "E-mail kan niet leeg zijn",
        "email_invalid": "Het ingevoerde e-mailadres lijkt ongeldig",
        "house_number_empty": "Huisnummer kan niet leeg zijn",
        "locality_empty": "Plaats kan niet leeg zijn",
        "postal_code_empty": "Postcode kan niet leeg zijn",
        "postal_code_invalid": "Geef een geldige Nederlandse postcode op, bijvoorbeeld \"8021 AB\""
      },
      "bulk": {
        "city_empty": "Vul hier de plaats in",
        "general_error": "Ongeldige invoer, controleer de rood gemarkeerde velden",
        "house_number_empty": "Vul hier het huisnummer in",
        "postal_code_empty": "Vul hier de postcode in",
        "postal_code_invalid": "Geef een geldige Nederlandse postcode op, bijvoorbeeld \"8021 AB\"",
        "serial_number_empty": "Vul hier het serienummer om door te gaan",
        "serial_number_invalid": "Dit is een ongeldig serienummer",
        "serial_number_registered": "Dit serienummer is al geregistreerd",
        "serial_number_unknown": "Er kan geen product gevonden worden met dit serienummer",
        "street_empty": "Vul hier de straat in"
      },
      "comfofan_silent": {
        "batch_number_empty": "Geef een serienummer op.",
        "batch_number_length": "Foutief serienummer. Het serienummer bestaat uit 8 - 12 cijfers.",
        "batch_number_type": "Een serienummer bestaat alleen uit cijfers."
      },
      "login": {
        "email_empty": "E-mail kan niet leeg zijn",
        "email_invalid": "Het ingevoerde e-mailadres lijkt ongeldig"
      },
      "product": {
        "serial_number_empty": "Serienummer kan niet leeg zijn"
      },
      "product_registration": {
        "email_empty": "E-mail kan niet leeg zijn",
        "email_invalid": "Het ingevoerde e-mailadres lijkt ongeldig",
        "postal_code_empty": "Postcode kan niet leeg zijn",
        "postal_code_invalid": "Geef een geldige Nederlandse postcode op, bijvoorbeeld \"8021 AB\""
      },
      "product_registrations_update": {
        "address_empty": "Adres kan niet leeg zijn",
        "description_empty": "Beschrijving kan niet leeg zijn",
        "locality_empty": "Plaats kan niet leeg zijn",
        "postal_code_empty": "Postcode kan niet leeg zijn",
        "postal_code_invalid": "Geef een geldige Nederlandse postcode op, bijvoorbeeld \"8021 AB\""
      },
      "profile": {
        "postal_code_invalid": "Geef een geldige Nederlandse postcode op, bijvoorbeeld \"8021 AB\""
      }
    },
    "version": {
      "back_link_text": "Terug naar beginscherm",
      "title": "App-info",
      "version_prefix": "Versie"
    }
  }]];
});